import React from "react"
import "./../css/termsAndConditions.css"

const TermsAndConditions = () => {
    return (
        <div className="TermsAndConditions">
            <div className="row">
                <div className="col"></div>
                <div className="col-md-10 col-lg-8 col-xl-6" style={{textAlign: "left", fontFamily: "sans-serif", padding: "10px"}} >
                    <h4 style={{textAlign: "center"}}>TERMS OF USE</h4>
                    <h5><strong>1.</strong><span>Background </span></h5>
                    <p className="display_flex"><strong>1.1</strong> This document is an electronic record in terms of (i) the Information Technology Act, 2000; (ii) the rules framed there under as applicable; and (iii) the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                    <p className="display_flex"><strong>1.2</strong> This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that requires publishing the Terms of Use for access or usage of the application and website and refers to the terms of use made available by Vahan Technologies India Pvt. Ltd on a WhatsApp based recruitment and training software platform designed to facilitate work force engagement (collectively referred to as the “Platform”).</p>
                    <p className="display_flex"><strong>1.3</strong> The Platform is owned, registered and operated by Vahan Technologies India Pvt. Ltd, a company incorporated under the Companies Act, 2013 and having its registered office at C-625, New Friends Colony, 3rd Floor, New Delhi – 110 025 ("Vahan" or “Company”).</p>
                    <p className="display_flex"><strong>1.4</strong> These terms of usage (“Terms of Use”) govern your use of the Platform and Services (as defined below) provided through the Platform. By using or visiting the Platform you shall be deemed to have read, understood and accepted to be bound by these Terms of Use.</p>
                    <p className="display_flex"><strong>1.5</strong> For the purpose of these Terms of Use, wherever the context so requires "You" or “Your” or “Affiliate Partner” shall mean any natural or legal person who has voluntarily signed up/registered on the Platform. The term “We”, “Us”, “Our” shall mean “Vahan” or the “Company”.</p>
                    <p className="display_flex"><strong>1.6</strong> We reserve the right to make changes to these Terms of Use at any time. Any such modifications will become effective immediately upon: (i) notifying such change to You; and (ii) posting it on the Platform, and your continued use of the Platform constitutes your agreement to such modifications.</p>


                    <h5><strong>2.</strong><span>Registration and related matters </span></h5>
                    <p className="display_flex"><strong>2.1</strong> In order to utilize the Platform, You will be first required to register on the Platform. While creating an Affiliate Partner Account, You agree to provide complete and accurate information about yourself. The Affiliate Partner may not impersonate any third party to create or use an account for anyone other than itself or provide an email address, or registration number other than your own or create multiple affiliate partner accounts on the Platform.</p>

                    <p className="display_flex"><strong>2.2</strong> The Affiliate Partner shall not allow: (a) any other person to share its Affiliate Partner Account; (b) any part of the Platform being cached in proxy servers and unauthorised access by third parties to its Affiliate Partner Account; and (c) access to the Platform through a single account and password being made available to multiple affiliate partners on a network.</p>
 
                    <p className="display_flex"><strong>2.3</strong> The Affiliate Partner may amend/change the information associated with its account on the Platform (the “Affiliate Partner Account”), by logging into the Platform. The manner in which the Company collects, uses, stores or discloses the Affiliate Partner’s personal data and sensitive personal data collected on the Platform, if any, has been outlined in the Company’s privacy policy available at https://vahan.co/privacy-policy. </p>

                    <p className="display_flex"><strong>2.4</strong> The Affiliate Partner shall be solely responsible for maintaining the confidentiality of its username, account password and shall be responsible for any and all activities that occur in connection with its Affiliate Partner Account. Under no circumstances shall the Company be liable for any loss or damage arising from the Affiliate Partner’s failure to comply with this clause. By participating in the Program, the Affiliate Partner warrants that all the data provided by it while registering onto the Platform is accurate and complete.</p>


                    <h5><strong>3.</strong> <span>Services</span></h5>

                    <p className="display_flex"><strong>3.1</strong> Through Our Platform, We offer an affiliate partner program (“Program”) that enables You to refer and onboard job seekers on to the Platform and convert them into hires in return for a stipulated reward.</p>

                    <p className="display_flex"><strong>3.2</strong> The Platform operates the Program that enables the Affiliate Partner to refer and onboard job seekers on to the Platform (“Onboarding”) and convert them into hires in accordance with the criteria determined by the Company (“Conversion” or “Converted”) in return for a reward on conversion (“Referrals”) (collectively the “Services”). Access to the Program also includes access to the distinctive business format and method developed and implemented by the Company in connection with the Program, utilizing and comprising the intellectual property, and certain standard operating procedures, plans, directions, specifications, methods, management and advertising techniques and identification schemes, part of which are contained in these Terms.</p>

                    <p className="display_flex"><strong>3.3</strong> The Affiliate Partners will be required to refer / source job seekers or candidates across various categories including and not limited to delivery jobs, BPO / Call Centre jobs, Feet on Street, Quick Service restaurants, commercial car drivers, Inside / Field sales executives, back office agents, hospitality jobs, housekeeping jobs, etc. for Onboarding and Conversion.</p>

                    <p className="display_flex"><strong>3.4</strong> The Affiliate Partner agrees and acknowledges that the Company reserves the right to change or suspend the services offered by it on the Platform at any time, including but not limited to the nature of these services, hours of availability, databases, hours of available and the software and/or hardware needed to access these services. The Company also reserves the right to restrict any affiliate partner from accessing the Platform and/or availing its services at its sole discretion.</p>

                    <p className="display_flex"><strong>3.5</strong> Subject to the Affiliate Partner’s compliance with these Terms, the Company grants a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to access and use the Platform. Any rights not expressly granted herein are reserved by the Company.</p>


                    <h5><strong>4.</strong> <span>Intellectual Property Rights</span></h5>

                    <p className="display_flex"><strong>4.1</strong> Intellectual Property Rights: The term “Intellectual Property” or “Intellectual Property Rights” shall mean copyrights, trademarks, patents, trade secrets, and other intellectual property, whether registered as such or not, of the Company, as may exist now and/or hereafter come into existence, and all renewals and extensions thereof, regardless of whether any of such rights arise under the applicable laws of any state, country or jurisdiction.</p>

                    <p><strong>4.2</strong> <strong>Copyright</strong></p>

                    <p className="display_flex"><strong>4.2.1</strong> All Intellectual Property Rights subsisting in the content included on the Platform and delivered to affiliate partners as part of the Program, including but not limited to the text, articles, photographs, books, images, illustrations, info-graphics, video material, audio material, and software used on or incorporated into the Platform (“Platform Content”), is solely held by the Company, and is protected by the applicable intellectual property laws. The compilation of all Platform Content is the exclusive property of the Company. The Affiliate Partner agrees not to decompile, reverse engineer, disassemble or create derivative works of any Platform Content accessible through the Platform, not to insert any code or product or manipulate the content of the Platform in any way that affects the user's experience, and not to use any data mining, data gathering or extraction method to exploit the Platform Content. The Company reserves the right to terminate these Terms if it, in its sole and absolute discretion, believes that the Affiliate Partner is in violation of this clause.</p>

                    <p className="display_flex"><strong>4.2.2</strong> The Platform Content is provided to the Affiliate Partner “as is” for the Affiliate Partner’s information and for the purpose of these Terms only and neither the Affiliate Partner itself nor a third party facilitated by the Affiliate Partner, may use, copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit for any other purposes whatsoever, the Platform Content without the prior written consent of the Company. The Company reserves all rights, not expressly granted in and to the Platform and the Company’s services.</p>
                                        
                    <p className="display_flex"><strong>4.2.3</strong> Trademarks: JobFinder, Vahan.ai & VahanMitra is/are the trademarks of the Company. The Platform Content contains the trademarks/trade dress of the Company. The trademarks and trade dress of the Company shall not be used by the Affiliate Partner in connection with any product or service that is not affiliated with the Company in any manner that is likely to (a) cause confusion among affiliates or potential affiliates; or (b) dilute the rights of the Company; or (c) to disparage or discredit the Company.</p>


                    <h5><strong>5.</strong> <span>Referrals</span></h5>

                    <p className="display_flex"><strong>5.1</strong> As consideration for Conversion, the Affiliate Partner shall be paid Referrals in accordance with Annexure 1 of these Terms. </p>

                    <p className="display_flex"><strong>5.2</strong> The Affiliate Partner understands and acknowledges that the referrals schedule and the applicable terms are subject to the discretion of the Company and may be changed at any time without prior notification to the Affiliate Partner. The Company shall communicate any material change in the applicable referral terms to the Affiliate Partner as soon as reasonably possible.</p>

                    <p className="display_flex"><strong>5.3</strong> All job applications submitted to the Company shall expire in 45 days. The Affiliate Partner shall have to ensure that the applications are Converted as early as possible.</p>

                    <p className="display_flex"><strong>5.4</strong> The metric for Conversion is subject to variable definitions, defined by the Company for each category of jobseeker.</p>

                    <p className="display_flex"><strong>5.5</strong> The referral / tier cycle resets every 30 days and is subject to change with or without prior notice to the Affiliate Partner.</p>


                    <h5><strong>6.</strong> <span>Payment Terms</span></h5>

                    <p className="display_flex"><strong>6.1</strong> The Affiliate Partner shall raise an invoice on the 10th, 20th or the 30th of every month along with the supporting documents necessary for reconciliation of the accounts. The payments towards the undisputed invoices shall be released within a period of 30 (thirty) days of receipt of the invoice by the Company.</p>

                    <p className="display_flex"><strong>6.2</strong> The Affiliate Partner shall also receive a payment (“Referrals”) through direct NEFT transfer in cases wherever applicable. The direct NEFT payment will be paid on tentative dates 10th, 20th or 30th of every month.</p>

                    <p className="display_flex"><strong>6.3</strong> The Affiliate Partner shall have the right to dispute the payments through one or more support channels available to them, i.e - Helpdesk, Email Support, Phone Support, In-person support, etc. You hereby consent to be contacted through any of these modes.</p>


                    <h5><strong>7.</strong> <span>Fraudulent Transactions on the Platform</span></h5>


                    <p className="display_flex"><strong>7.1</strong> In the event the Company becomes aware of any fraudulent transactions undertaken on the Platform by the Affiliate Partner, the Company shall have the right to immediately terminate these Terms with the Affiliate Partner without any notice.</p>

                    <p className="display_flex"><strong>7.2</strong> In the event of a fraud as determined by the Company, the Company shall also have the right to withhold all payments towards the Referrals indefinitely and reserves the right to take any and all recourses available to it under applicable law including the remedies under criminal law against the relevant Affiliate Partner.</p>


                    <h5><strong>8.</strong> <span>Limitations and Disclaimer of Warranty</span></h5>

                    <p className="display_flex"><strong>8.1</strong> The Platform and the Company’s services and each portion thereof are provided “as is” without warranties of any kind either express or implied. To the fullest extent possible pursuant to applicable laws of India, the Company disclaims all warranties, express or implied, with respect to the Platform and these services and each portion thereof, including, but not limited to, the educational videos, books and other content available on the Platform. The Company makes no representation or warranty that any Platform Content or material associated with their services displayed on or offered through the Platform is accurate, complete, appropriate or reliable. The Company also makes no representations or warranties that the Platform Content will meet the Affiliate Partner’s requirements and/or its access to and use of these services will be uninterrupted or error-free, free of viruses, malicious code, or other harmful components, or otherwise secure</p>

                    <p className="display_flex"><strong>8.2</strong> Under no circumstances shall the Company or any others involved in creating this Platform be liable for any damages or injury, including any direct, indirect, incidental, special, consequential, punitive or other damages resulting from any circumstance involving this Platform (including but not limited to damages or injury caused by error, omission, interruption, defect, failure of performance, misdirected or redirected transmissions, failed internet connections, unauthorized use of this Platform, lost data, delay in operation or transmission, breach of security, claim arising out of any person not satisfied with the services provided by an Affiliate Partner on the Platform, line failure, defamatory, offensive or illegal conduct of any Affiliate Partner on this Platform, or computer virus, worm, trojan horse or other harmful component), even if the Affiliate Partner have advised the Company in advance of the possibility of such damage.</p>

                    <p className="display_flex"><strong>8.3</strong> If the Affiliate Partner is dissatisfied or harmed by this Platform or anything related to the Platform, it may terminate these Terms in accordance with these Terms.</p>

                    <p className="display_flex"><strong>8.4</strong> The Company does not guarantee that the Platform will function without interruption or errors in functioning. In particular, the operation of the Platform may be interrupted due to maintenance updates, or system or network failures. In the event of interruption in accessing the Platform due to the above- mentioned reasons, the Company shall inform the Affiliate Partner of the same, and the Company disclaims all liability with respect to delay in provision of its services due to the same</p>


                    <h5><strong>9.</strong> <div><span>Affiliate’s Representations and Warranties</span> <br/><small style={{fontSize: "16px"}}>The Affiliate Partner hereby represents and warrants that:</small></div></h5>

                    <p className="display_flex"><strong>9.1</strong> It has full power and authority to enter into these Terms and perform its obligations hereunder;</p>

                    <p className="display_flex"><strong>9.2</strong> If it is a natural person, it is at least of 18 years of age and competent to enter into and perform these Terms;</p>

                    <p className="display_flex"><strong>9.3</strong> It has not entered into, and during the pendency of these Terms, will not enter into any agreement that would prevent it from complying with the terms of these Terms;</p>

                    <p className="display_flex"><strong>9.4</strong> It will comply with all applicable laws in its performance of these Terms, including holding and complying with all permits, licenses, registrations, and other governmental authorizations necessary for such performance; and</p>

                    <p className="display_flex"><strong>9.5</strong> It shall require all third parties working with it, or on its behalf, to comply with all applicable terms and conditions set forth in these Terms and all Applicable Laws and regulations. </p>


                    <h5><strong>10.</strong> <span>Indemnification</span></h5>

                    <p className="display_flex"><strong>10.1</strong> The Affiliate Partner agrees to indemnify, defend and hold harmless the Company, its affiliates, officers, partners, employees, consultants and representatives, from and against all losses, expenses, damages, costs, claims and demands, including reasonable attorney's fees and related costs and expenses, due to or arising out of: (a) the Affiliate Partner’s breach or failure to comply with these Terms; or (b) where any information the Affiliate Partner submits, emails, or otherwise transmits to the Company any information that violates third party rights or applicable laws. The Company reserves the right, at its own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by the Affiliate Partner, and in such case, the Affiliate Partner agrees to fully cooperate with such defence and in asserting any available defences.</p>

	                <p className="display_flex"><strong>10.2</strong> The Company shall not be liable for indirect, incidental, special, exemplary, punitive or consequential damages, including lost profits, lost data, personal injury, or property damage related to, in connection with, or otherwise resulting from any use of the Platform or its services, even if the Company has been intimated / advised of the possibility of such damages. The Company shall not be liable for any damages, liability or losses arising out of the Affiliate Partner’s use of or reliance on the Company’s services or the Affiliate Partner’s inability to access or use these services. The Company shall not be liable for delay or failure in performance resulting from causes beyond the Company’s reasonable control. In the event where the Company’s liability cannot be excluded, then the Company’s total liability to the Affiliate Partner in connection with these Terms for all damages, losses, and causes of action shall not exceed Referrals received by the Affiliate Partner in the 1 (one) month preceding the date of claim sought against the Company.</p>


                    <h5><strong>11.</strong> <span>Data Privacy</span></h5>

                    <p className="display_flex"><strong>11.1</strong> The Affiliate Partner shall process the personal data of the Onboarded and/or Converted hires in accordance with all Applicable Laws with respect to data privacy.</p>

                    <p className="display_flex"><strong>11.2</strong> If the Affiliate Partner becomes aware of or reasonably suspects that there has been any accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to any Personal Data of the Onboarded and/or Converted hires, it shall immediately notify the Company, investigate and provide the Company with detailed information of the breach and take necessary steps to mitigate the effects and to minimise any damage resulting from such breach.</p>

                    <p className="display_flex"><strong>11.3</strong> The Affiliate Partner shall indemnify the Company against any losses, claims and liabilities arising as a result of such breach of any Personal Data of the Onboarded and/or Converted hires.</p>

                    <p className="display_flex"><strong>11.4</strong> Upon the expiration or termination of these Terms, the Affiliate Partner shall cease to retain the Personal Data of the Onboarded and/or Converted hires that it no longer requires under these Terms and, at the Company’s option, either return it to the respective Onboarded or Converted hire or destroy such Personal Data.</p>

                    <p className="display_flex"><strong>11.5</strong> Where Personal Data is transferred by or on behalf of the Affiliate Partner to a country or territory outside of India, the Affiliate Partner shall take reasonable steps to ensure that the standard of protection applied to such Personal Data is in accordance with Applicable Laws.</p>



                    <h5><strong>12.</strong> <span>Confidentiality</span></h5>

                    <p className="display_flex"><strong>12.1</strong> The term Confidential Information shall mean and include any and all information provided by the Company to the Affiliate Partner, or to which the Affiliate Partner has or is given access, in whatever form, verbal or otherwise, including, but not limited to, the terms of these Terms, the Company’s business plans, marketing plans, financial records and analysis, research, technical specifications, marketing-sales-pricing data, designs, agreements, business or trade secrets, know-how, software or other Intellectual Property, whether or not identified as Confidential Information, in any media, electronic or otherwise, and any other materials identified in writing as confidential information</p>

                    <p className="display_flex"><strong>12.2</strong> During the Term of these Terms and for three (3) years thereafter, the Affiliate Partner shall keep confidential all Confidential Information.</p>

                    <p className="display_flex"><strong>12.3</strong> The Affiliate Partner shall only use Confidential Information for the purpose of fulfilling its obligations under these Terms and shall not, without the prior written consent of the Company, disclose any such Confidential Information to any person other than the Affiliate Partner's employees or professional advisors on a need to know basis, for the performance of the Affiliate Partner’s obligations under these Terms and who have, where reasonably required by the Company, signed a confidentiality undertaking (in a form reasonably satisfactory to the Company) or otherwise as permitted in writing by the Company.</p>

                    <p className="display_flex"><strong>12.4</strong> The obligations set out above shall not apply to Confidential Information which the Affiliate Partner can show to the Company’s reasonable satisfaction:
                        <br/><br/>(a) Was known to the Affiliate Partner (without obligation to keep the same confidential) at the date of its disclosure;
                        <br/><br/>(b) Is after the date of disclosure lawfully acquired by the Affiliate Partner in good faith from an independent third party who is not subject to any obligation of confidentiality in respect of such Confidential Information;
                        <br/><br/>(c) In its entirety was at the time of disclosure or has become public knowledge otherwise than by reason of the Affiliate Partner's neglect or breach of the restrictions set out in these Terms;
                        <br/><br/>(d) Is independently developed by the Affiliate Partner without access to any or all of the Company’s Confidential Information; or
                        <br/><br/>(e) Is required by applicable law, judicial action or a Governmental Authority to be disclosed in which event the Affiliate Partner shall provide a written notification to the Company shall take all reasonable steps to consult and take into account the reasonable requirements of the Company in relation to such disclosure.
                    </p>

                    <p className="display_flex"><strong>12.5</strong> Upon the expiration or termination of these Terms, or upon Company's request, Affiliate Partner shall, in accordance with the instructions of the Company, promptly return or destroy all copies or derivatives of Confidential Information provided by the Company and deliver to the Company a certificate executed by the Affiliate Partner confirming its compliance with this clause.</p>



                    <h5><strong>13.</strong> <span>Termination</span></h5>

                    <p className="display_flex"><strong>13.1</strong> The Company may terminate these Terms immediately without notice in the event of any breach by the Affiliate Partner of these Terms or any of the Company’s policies applicable to the Program, as posted on the Platform from time to time or upon a misuse of the Program by the Affiliate Partner.</p>

                    <p className="display_flex"><strong>13.2</strong> Either You or We may terminate these Terms, for any or no reason, at any point in time by providing the other party a Seven (7) day notice. The Affiliate Partners acknowledge that for the obligations of the parties already performed, these Terms will continue to apply.</p>

                    <p className="display_flex"><strong>13.3</strong> In the event of any termination or expiration of these Terms, the following sections of these Terms shall survive: all provisions regarding ownership of intellectual property, indemnification, disclaimer of warranties and limitations of liability, and the provisions of this section.</p>

                    <p className="display_flex"><strong>13.4</strong> The Affiliate Partner agrees that upon the termination of these Terms, the Company may delete all information related to the terminating Affiliate Partner with respect to the Services provided by such Affiliate Partner </p>



                    <p className="display_flex"><strong style={{fontWeight: "bolder"}}>14</strong> <span><b><u>Entire Agreement:</u></b> Unless otherwise specified herein, these Terms constitutes the entire agreement between the Affiliate Partner and the Company in respect of the Affiliate Partner’s use of the Platform and supersedes all previous written and oral agreements between the Affiliate Partner and the Company, if any. </span></p>
 
                    <p className="display_flex"><strong style={{fontWeight: "bolder"}}>15</strong> <span><b><u>Severability:</u></b> If any part of these Terms is determined to be invalid or unenforceable pursuant to applicable laws of India, including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed to be superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms shall continue in effect.</span></p>

                    <p className="display_flex"><strong style={{fontWeight: "bolder"}}>16</strong> <span><b><u>Relationship between the parties:</u></b> Notwithstanding anything to the contrary contained in these Terms, the relationship between the parties shall be on a principal-to-principal basis and nothing in these Terms will be construed as creating an agency, partnership, joint venture, association of persons or employment relationship between the parties.    	</span></p>

                    <p className="display_flex"><strong style={{fontWeight: "bolder"}}>17</strong> <span><b><u>Assignment:</u></b> The Affiliate Partner shall not be entitled to assign these Terms to any person without the consent of the Company. It is clarified that the Company shall have the right to assign these Terms to a third party without the consent of the Affiliate Partner.</span></p>

                    <p className="display_flex"><strong style={{fontWeight: "bolder"}}>18</strong> <span><b><u>Waiver:</u></b> No delay or omission to exercise any right, power or remedy accruing to any party, upon any breach or default of any Party hereto under these Terms, shall impair any such right, power or remedy of any party nor shall it be construed to be a waiver of any such breach or default, or an acquiescence therein, or of any similar breach or default thereafter occurring.</span></p>

                    <p className="display_flex"><strong style={{fontWeight: "bolder"}}>19</strong> <span><b><u>Governing Law:</u></b> This Terms of Use document shall be governed by and constructed in accordance with the applicable laws of India without reference to conflict of laws principles. Subject to Section 20 below, any disputes arising in relation to, or under, this Terms of Use and the Privacy Policy, shall be subject to the exclusive jurisdiction of the courts of New Delhi.</span></p>



                    <h5><strong>20.</strong> <span>Dispute Resolution</span></h5>

                    <p className="display_flex"><strong>20.1</strong> In the event of any dispute, controversy or claim arising out of or relating to these Terms or any subsequent amendments to these Terms including, without limitation, the breach of the terms hereunder, termination, validity or invalidity thereof, or any non-contractual issues relating to these Terms (each, a “Dispute”), we will each appoint a designated officer to meet for the purpose of endeavouring to resolve such Dispute amicably or to negotiate for an adjustment to such provision.</p>

                    <p className="display_flex"><strong>20.2</strong> All Disputes, which are not settled pursuant to the issue resolution procedures set forth in Section 20.1 above, will be referred and settled by final and binding arbitration by a sole arbitrator mutually appointed by both of us in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time, and the seat of arbitration shall be at Delhi.</p>

                    <p className="display_flex"><strong style={{fontWeight: "bolder"}}>21</strong> <span><b><u>Grievance officer:</u></b> In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                        <br/>
                        <br />Name: ____________
                        <br />Email: ____________
                        <br />Number: ____________
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </span></p>


                </div>
                <div className="col"></div>
            </div>
        </div>
    )
}


export default TermsAndConditions