import axios from 'axios';

export const verifyOTP = async (phoneNumber, otp) => {
    const data = new FormData();
    data.append('phoneNumber', phoneNumber);
    data.append('otp', otp);
    const url = process.env.REACT_APP_BACKEND_URL + '/login';
    return axios.post(url, data, { withCredentials: true });
};

export const validateSession = () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/auth';
    return axios.get(url, { withCredentials: true });
};

export const inValidateSession = () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/logout';
    return axios.get(url, { withCredentials: true });
};
