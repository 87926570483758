import React from "react"
import { useHistory } from "react-router"
import "./../../css/mainLogoHeader.css"
import { inValidateSession } from "../../service/loginService"
import { Button } from "@rmwc/button"

const MainLogoHeader = props => {

    const history = useHistory()

    const logout = async () => {
        await inValidateSession()
        history.replace("/")
        
    }

    return (
        <div className="training_session_main" >
            {!props.backButton && <img className="vahan_logo" alt="vahan" src="/image/mitra-logo.png" />}
            {props.backButton && <button onClick={() => {history.goBack()}}> <i className="fa fa-arrow-left back_icon"></i> </button>}
            <button className="signout_button" onClick={logout}>Sign out</button>
        </div>
    )
}

export default MainLogoHeader;