import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '@rmwc/button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import * as homeService from './../service/homeService';
import NavigationTab from './common/NavigationTab.component';
import { logEvent, setTrackingUser } from '../service/tracking';
import trackingConstants from '../constants/trackingConstants';
import JobsTable from './JobsTable.component';
import BasicInfoForm from './BasicInfoForm';

const DEFAULT = 'DEFAULT';
let deferredPrompt;  
const Home = ({ mitra,comingFromLoginPage }) => {
	const history = useHistory();
	const { register, handleSubmit, errors } = useForm();
	const [ category, setCategory ] = useState(DEFAULT);
	const [ availableCategories, setAvailableCategories ] = useState([]);
	const [ companyCategoryMapping, setCompanyCategoryMapping ] = useState([]);
	const [ city, setCity ] = useState(DEFAULT);
	const [ company, setCompany ] = useState(DEFAULT);
	const [ jobLocation, setJobLocation ] = useState(DEFAULT);
	const [ leadAlreadyExistsMsg, setLeadAlreadyExistsMsg ] = useState('');
	const [ clients, setClients ] = useState([]);
	const [ cities, setCities ] = useState([]);
	const [ jobs, setJobs ] = useState([]);
	const [ jobLocations, setJobLocations ] = useState([]);
	const [ jobId, setJobId ] = useState('');
	const [ jobDemandId, setJobDemandId ] = useState([]);
	const [ candidateForm, setCandidateForm ] = useState(false);
	const [ homeScreenModal, sethomeScreenModal ] = useState(true);
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ name, setName ] = useState('');
	const [ phoneNumberVerified, setPhoneNumberVerified ] = useState(null);
	const [errorsFile, setErrorsFile] = useState([]);
	const [ bulkCandidateUploadForm, setBulkCandidateUploadForm ] = useState(false);
	const [ bulkCandidateFile, setBulkCandidateFile ] = useState('');
	const [ dlNumber, setDlNumber ] = useState(null);
	const [ extraRequiredFields, setExtraRequiredFields ] = useState([]);
	const [ disableSubmit, setDisableSubmit ] = useState(false);
	
	useEffect(() => {
		setTrackingUser(mitra);
		if(comingFromLoginPage){
			sethomeScreenModal(false)
		}
		logEvent('OpenHomeTab', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		
		window.addEventListener("beforeinstallprompt", (e) => {
		// Prevent the mini-infobar from appearing on mobile
		e.preventDefault();
		// Stash the event so it can be triggered later.
		deferredPrompt = e;
		// Update UI notify the user they can install the PWA
		sethomeScreenModal(false);
		});

		const getPremiumJobsCities = () => {
			homeService.getPremiumJobsCities().then((result) => {
				if (result && result.data.cities) {
					const cities = result.data.cities;
					const totalCities = cities.map((c) => c.city);
					setCities(Object.values(totalCities));
				}
			});
		};
		getPremiumJobsCities();
	}, []);
	
	const handleJobTypeChange = async (e) => {
		const chosenCategory = e.target.value;
		setCategory(chosenCategory);
		if(company && phoneNumber.length === 10 && chosenCategory) {
			const duplicateLead = await validateLeadPhoneNumber(phoneNumber, company, chosenCategory);
			if(duplicateLead) {
				setPhoneNumberVerified(true);
			} else {
				setPhoneNumberVerified(false);
				getJobLocations(city, company);
			}
		} else if(bulkCandidateUploadForm && company && chosenCategory) {
			getJobLocations(city, company);
		}
	};

	const handleCityChange = (e) => {
		setCity(e.target.value);
		setCompanyCategoryMapping([]);
		setJobs([]);
		setCompany(DEFAULT);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setJobLocations([]);
		setPhoneNumberVerified(false);
		getClientsAndCompanies(e.target.value);
	};

	const handleCityChangeForBulkUpload = (e) => {
		setCity(e.target.value);
		setCompanyCategoryMapping([]);
		setJobs([]);
		setCompany(DEFAULT);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setJobLocations([]);
		setPhoneNumberVerified(false);
		getClientsAndCompaniesForBulkUpload(e.target.value);
	};

	const handleJobChange = (jobId, jobDemandId) => {
		setJobId(jobId);
		setJobDemandId(jobDemandId);
		setPhoneNumberVerified(false);
	};

	const handleJobLocationChange = (e) => {
		const chosenJobLocation = e.target.value;
		setJobLocation(chosenJobLocation);
		getJobs(category, city, company, chosenJobLocation);
	};

	const handleClientChange = (e) => {
		setPhoneNumberVerified(false);
		const chosenClient = e.target.value;
		setCompany(chosenClient);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setAvailableCategories(companyCategoryMapping[chosenClient]);
		setJobs([]);
		setJobLocations([]);
		homeService.getExtraRequiredFields(chosenClient)
			.then((result) => {
				if(result && result.data) {
					setExtraRequiredFields(result.data.extraRequiredFields);
				}
			});
	};

	const handleDLNumberChange = (e) => {
		setDlNumber(e.target.value);
	}

	const closeModal = async () => {
		sethomeScreenModal(true);
	};

	const handleInstallClick = (e) => {
		sethomeScreenModal(true);
		deferredPrompt.prompt();
		deferredPrompt.userChoice.then((choiceResult) => {
		  if (choiceResult.outcome === 'accepted') {
			console.log('User accepted the install prompt');
		  } else {
			console.log('User dismissed the install prompt');
		  }
		});
	};

	const handleNumberChange = (event) => {
		setPhoneNumberVerified(false);
		setPhoneNumber(event.target.value);
	};

	const handleNameChange = (event) => {
		setName(event.target.value);
	};

	const validateLeadPhoneNumber = async (phoneNumber, company, category) => {
		try {
			if (phoneNumber.length === 10 && company) {
				let url = `${process.env.REACT_APP_BACKEND_URL}/lead/validate?phoneNumber=${encodeURIComponent(
					phoneNumber)}&companyName=${(company)}&category=${(category)}`;
				let response = await axios.get(url, { withCredentials: true });
				const duplicateLead = response.data.validLead === false;
				if (duplicateLead) {
					setLeadAlreadyExistsMsg(response.data.message);
					logEvent('LeadIsDuplicate', trackingConstants.EVENT_GROUP_ADD_LEAD, trackingConstants.EVENT_SCREEN_ADD_LEAD);
				}
				return duplicateLead;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetPhoneNumberClick = async () => {
		setCandidateForm(true);
		logEvent('AddLead', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_HOME_TAB);
	};

	const openBulkCandidateUploadForm = () => {
		setBulkCandidateUploadForm(true);
		logEvent('BulkAddLead', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_HOME_TAB);
	}

	const handleBackArrowClick = () => {
		setCandidateForm(false);
		setBulkCandidateUploadForm(false);
		setLeadAlreadyExistsMsg('');
		setPhoneNumber('');
		setCity(DEFAULT);
		setCompany(DEFAULT);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setJobs([]);
		setClients([]);
		setBulkCandidateFile('');
		setErrorsFile([]);
	};

	const getClientsAndCompanies = (city) => {
		if (city) {
			homeService.getClientsAndCompanies(city).then((result) => {
				if (result && result.data.viewData.companies) {
					const companyAndCategories = result.data.viewData.companies;
					setClients(Object.keys(companyAndCategories));
					setCompanyCategoryMapping(companyAndCategories);
				}
			});
		}
	};

	const getClientsAndCompaniesForBulkUpload = (city) => {
		if (city) {
			homeService.getClientsAndCompaniesForBulkUpload(city).then((result) => {
				if (result && result.data.viewData.companies) {
					const companyAndCategories = result.data.viewData.companies;
					setClients(Object.keys(companyAndCategories));
					setCompanyCategoryMapping(companyAndCategories);
				}
			});
		}
	};

	const getJobLocations = (city, company) => {
		if(city === 'Work from Home') {
			setJobLocations(['Work from Home']);
		} else {
			if (city && company) {
				homeService.getJobLocations(city, company).then((result) => {
					if (result && result.data.viewData.jobLocations) {
						let jobLocations = result.data.viewData.jobLocations;
						jobLocations = jobLocations.map((c) => c.name);
						setJobLocations(jobLocations);
					}
				});
			}
		}
	};

	const getJobs = (category, city, companyName, jobLocation) => {
		if (city && category && companyName && jobLocation) {
			homeService.getJobs(category, city, companyName, jobLocation).then((result) => {
				if (result && result.data.viewData.jobs) {
					setJobs(result.data.viewData.jobs);
				}
			});
		}
	};

	const handleChangeFile = (file) => {
		setBulkCandidateFile(file);
	}

	const submitBulkUploadForm = () => {
		homeService.postBulkUploadForm(bulkCandidateFile, city, category, company, jobId, jobLocation, jobDemandId)
			.then((result) => {
				alert(result.data);
				history.push('/leads');
			})
			.catch((error) => {
				setErrorsFile(error.response.data);
			});
	};

	const downloadErrorFile = () => {
		var CsvString = "";
		errorsFile.map((RowItem) => {
		  RowItem.map((ColItem) => {
			CsvString += ColItem;
		  });
		  CsvString += "\r\n";
		});
		CsvString = "data:application/csv," + encodeURIComponent(CsvString);
	   var x = document.createElement("A");
	   x.setAttribute("href", CsvString );
	   x.setAttribute("download","error.csv");
	   document.body.appendChild(x);
	   x.click();
	  }

	const submitBasicForm = async () => {
		setDisableSubmit(true);
		const basicInfo = {
			name,
			phoneNumber,
			city,
			category,
			company,
			mitraId: mitra.id,
			jobId,
			jobDemandId,
			jobLocation,
			companyName: company
		};
		if (extraRequiredFields.includes('dlNumber')) {
			basicInfo.dlNumber = dlNumber;
		}
		homeService.postBasicInfoForm(basicInfo).then((response) => {
			if (response.data) {
				delete basicInfo.mitraId
				localStorage.setItem("lead", JSON.stringify(basicInfo))
				logEvent('AddLead', trackingConstants.EVENT_GROUP_ADD_LEAD, trackingConstants.EVENT_SCREEN_ADD_LEAD);
				alert('You have submitted your candidate info. You can check their referral status on the candidates page after some time!');
				history.push("/moreInfo");
			}
		});
	};

	const isBasicFormValid = () => {
		return phoneNumber 
			&& city !== DEFAULT
			&& category !== DEFAULT
			&& company !== DEFAULT
			&& !phoneNumberVerified
			&& jobLocation !== DEFAULT
			&& jobId !== ''
			&& (!extraRequiredFields.includes('dlNumber') || dlNumber);
	};

	const isBulkCandidateUploadFormValid = () => {
		return city !== DEFAULT && category !== DEFAULT && company !== DEFAULT && jobLocation !== DEFAULT && jobId !== '' && bulkCandidateFile !== '';
	}

	if(bulkCandidateUploadForm) {
		return (
			<div className="Home">
				<h2 className="heading_1 add-candidate">
					<Button id="back-to-home" icon={require('./../arrow_back.svg')} 
					onClick={handleBackArrowClick} 
					/>
					Bulk Add Candidates
				</h2>
				<h4 className="heading_1 form-sub-heading">Basic Details</h4>
				<form>
					<div className="form_control">
					<h5 className="extraCompanies">The following clients are not supported for bulk upload: Shadowfax, Shadowfax 3PL, Dunzo. Please use the "Add Single Candidate" option for these clients.</h5>
						<div className="input-text-title field-required">City</div>
						<select
							id="basic-form-city"
							name="city"
							onChange={handleCityChangeForBulkUpload}
							defaultValue={DEFAULT}
							ref={register({ required: true })}
							value={city}
						>
							<option value={DEFAULT} disabled>
								City
							</option>
							{cities.map((city) => (
								<option className="Dropdown-option" key={city} value={city}>
									{city}
								</option>
							))}
						</select>
					</div>
					<div className="form_control">
						<div className="input-text-title field-required">Company / Client</div>
						<select
							id="basic-form-company"
							name="company"
							onChange={handleClientChange}
							defaultValue={DEFAULT}
							ref={register({ required: true })}
							value={company}
						>
							<option value={DEFAULT} disabled>
								Company / Client
							</option>
							{clients.map((company) => (
								<option className="Dropdown-option" key={company} value={company}>
									{company}
								</option>
							))}
						</select>
					</div>
					<div className="form_control">
						<div className="input-text-title field-required">Job Category</div>
						<select
							id="basic-form-category"
							name="category"
							onChange={handleJobTypeChange}
							defaultValue={DEFAULT}
							ref={register({ required: true })}
							value={category}
						>
							<option value={DEFAULT} disabled>Job Category</option>
							{	availableCategories.map((category) => {
									return <option key={category} value={category}>{category}</option>})
							}
						</select>
					</div>
					<div className="form_control">
						<div className="input-text-title field-required">Job Location</div>
						<select
							id="basic-form-job-location"
							name="jobLocation"
							onChange={handleJobLocationChange}
							defaultValue={DEFAULT}
							ref={register({ required: true })}
							value={jobLocation}
						>
							<option value={DEFAULT} disabled>Job Location</option>
							{	jobLocations.map(jobLocation => {
									return <option key={jobLocation} value={jobLocation}>{jobLocation}</option>})
							}
						</select>
					</div>
					{jobs && jobs.length ? (<div className="form_control padding-class"><JobsTable rows={jobs} onJobChange={job => handleJobChange(job.id, job.jobDemandId)}></JobsTable></div>)
						:null}
					
					<div className="form_control" >
						<div className="input-text-title field-required">Candidate Details</div>
						<input 
							id="basic-form-bulk-upload"
							name="file"
							type="file"
							accept=".xlsx"
							ref={register({ required: true })}
							onChange={e => handleChangeFile(e.target.files[0])}
						/>
						<h5 className="blue">Headers Name should be [candidateName, candidatePhoneNumber]</h5>
						<h5 className="blue">candidateName, candidatePhoneNumber are mandatory fields</h5>
					</div>
					<div className="form_control">
						{errorsFile.length>0 ? (
							<h5 className="instruction"><b>Upload failed, <button onClick={() => downloadErrorFile()}>click here</button> to view errors. Reupload the entire file again after correcting the errors.</b></h5>
							) : <></> }
					</div>
					<input id="basic-form-bulk-submit" className="Home" type="button" value="Upload" disabled={!isBulkCandidateUploadFormValid()} onClick={submitBulkUploadForm} />
				</form>
				<div className="navigation-container">
					<NavigationTab currentTab={0} mitra={mitra} />
				</div>
			</div>
		);
	} else if (!candidateForm) {
		return (
			<div className="Home">
				<img src = "/image/Mitra-monogram.png" className="mitra-small-logo"></img>
				<h2 className="heading_1" style={{padding: '2rem 0'}}>Hi, {mitra.name}</h2>
  				<div>
					<h4 className="add-candidate-title">Add Candidates</h4>
					<div className="buttons-contained-enabled-copy single-candidate">
						<Button id="add-single-candidate" raised label="Add Single Candidate" onClick={handleGetPhoneNumberClick} />
					</div>
					<div className="buttons-contained-enabled-copy">
						<Button id="add-multiple-candidate" raised label="Add Multiple Candidates" onClick={openBulkCandidateUploadForm} />
					</div>
				</div>
				{!homeScreenModal ? (
					<div className="otp-popup">
						<div className="otp-popup-inner">
							<div className="icon-body" onClick={closeModal}>
								<img alt="close" src="/image/icon_close.png" style={{ padding: '2rem 2rem 0' }} />
							</div>
							<h2 className="heading_1">
								Hi {mitra.name}, <br />Welcome to Vahan{' '}
							</h2>
							<h3 className="subheading-popup">
								Please Add to Home screen<br /> for easy access
							</h3>
							<div className="btn-home-screen">
								<Button id="add-home-screen" raised label="ADD TO HOME SCREEN" onClick={handleInstallClick} />
							</div>
							<h5 className="footer-popup">It takes very less space on your phone</h5>
						</div>
					</div>
				) : null}
				<div className="navigation-container">
					<NavigationTab currentTab={0} mitra={mitra} />
				</div>
			</div>
			)
		} else {
			return (
				<div className="Home">
					<h2 className="heading_1 add-candidate">
						<Button id="back-to-home" icon={require('./../arrow_back.svg')}
						onClick={handleBackArrowClick} 
						/>
						Add Candidate
					</h2>
					<BasicInfoForm mitra={mitra} />
					{/* <form>
						<div className="form_control">
							<div className="input-text-title field-required">Candidate Name</div>
							<input
								id="basic-form-candidate-name"
								label="Candidate Name"
								name="name"
								type="text"
								onChange={handleNameChange}
								placeholder="Candidate Name"
								ref={register({ required: true, minLength: 2 })}
							/>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Candidate Phone number</div>
							<input
								id="basic-form-phone-number"
								label="Phone Number"
								name="phoneNumber"
								type="tel"
								onChange={handleNumberChange}
								placeholder="Candidate Phone Number"
								ref={register({ required: true, minLength: 10, maxLength: 10 })}
							/>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">City</div>
							<select
								id="basic-form-city"
								name="city"
								onChange={handleCityChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={city}
							>
								<option value={DEFAULT} disabled>
									City
								</option>
								{cities.map((city) => (
									<option className="Dropdown-option" key={city} value={city}>
										{city}
									</option>
								))}
							</select>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Company / Client</div>
							<select
								id="basic-form-company"
								name="company"
								onChange={handleClientChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={company}
							>
								<option value={DEFAULT} disabled>
									Company / Client
								</option>
								{clients.map((company) => (
									<option className="Dropdown-option" key={company} value={company}>
										{company}
									</option>
								))}
							</select>
						</div>
						<div className="form_control" hidden={!extraRequiredFields.includes('dlNumber')}>
							<div className="input-text-title field-required">DL Number</div>
							<input
								id="basic-form-dl-number"
								label="DL Number"
								name="dlNumber"
								type="text"
								onChange={handleDLNumberChange}
								placeholder="Driving License Number"
								ref={register({ required: extraRequiredFields.includes('dlNumber'), minLength: 5 })}
							/>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Job Category</div>
							<select
								id="basic-form-category"
								name="category"
								onChange={handleJobTypeChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={category}
							>
								<option value={DEFAULT} disabled>Job Category</option>
								{	availableCategories.map((category) => {
										return <option key={category} value={category}>{category}</option>})
								}
							</select>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Job Location</div>
							<select
								id="basic-form-job-location"
								name="jobLocation"
								onChange={handleJobLocationChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={jobLocation}
							>
								<option value={DEFAULT} disabled>Job Location</option>
								{	jobLocations.map(jobLocation => {
										return <option key={jobLocation} value={jobLocation}>{jobLocation}</option>})
								}
							</select>
						</div>
						{phoneNumberVerified === true ? (
							<div className="form_control">
								<span className="errMsg">{leadAlreadyExistsMsg}</span>
							</div>
						) : null}
						{jobs && jobs.length ? (<div className="form_control padding-class"><JobsTable rows={jobs} onJobChange={job => handleJobChange(job.id, job.jobDemandId)}></JobsTable></div>)
						:null}
						<input id="basic-form-submit" className="Home" type="button" value="Next" disabled={!isBasicFormValid() || disableSubmit} onClick={submitBasicForm} />
					</form> */}
					<div className="navigation-container">
						<NavigationTab currentTab={0} mitra={mitra} />
					</div>
				</div>
			);
		}
};

export default Home;