const mitras = [
    'fe2dfad8-08b4-481a-be21-db1d741c0f15',
    '77bab636-6e97-4bb8-90a4-a3771c4d4220',
    '5b141fa5-cf56-4aa1-a3d2-cfdd26f05a48',
    'da8c578e-c234-4e55-9c06-4ecb7dd0f3f6',
    '10bdda2e-5c47-41ab-851a-bb7924d35039',
    '01be2db3-9ef5-4f99-8653-b733ed20be9f',
    'a4f12bd2-e31a-4237-9e22-11916d373c83',
    '71b5641d-e9d9-43d8-9675-c10e4559e9f3',
    '79d5ba96-ba26-464a-8afd-b7525fd75b5c',
    'a7afad7f-c51d-47b5-bc48-e1aa43f486fe',
    '84b2a463-9b02-497d-a10f-30eb768d23ce',
    '631cdae3-8ac4-49f3-8cf8-36a728744577',
    '2049d08b-b0c7-44a5-b6c6-9ba410812585',
    'a850a3ac-1842-417d-b036-97e25f783cd1',
    'a4c3e4bc-c77d-4866-966e-2b8a9d3969a9',
    'f2056f68-a0ee-4a90-a08d-9783d7b903c9',
    'dda49900-7f5e-48d9-b641-6ada0b0b86ba',
    'c0928520-61e1-411d-917f-d0a5c2f6c034',
    '57fae688-f320-4d31-af91-0a110e0ce4c5',
    '72a42d6a-1e41-421c-8ae0-1861a4e27b03',
    '7277842f-c54d-4a62-addc-599576cf07f7',
    '2462dc80-0323-4d22-a208-b9ef8584453e',
    '1ca762a4-6d9e-4a36-a641-0b09d74538f0',
    '387570d3-4fe9-4ad2-8969-170765b98b78',
    'faed06d0-eb57-4c01-9e18-8ab1839c234a',
    'a3afc763-433a-449f-9438-ba6bb4984d03',
    '62d959cc-6c89-400a-bc20-9708a9c7ee8e',
    '3e17e459-f7e6-4571-a387-8e33ca802ac5',
    'cff175a5-d8a1-452f-9617-a7e195330747',
    'ab7554b6-0c3b-4803-b490-49565be325b4',
    '10171a0e-6f2d-47b3-89a2-d7c52683900f',
    'a0e4cbec-2185-4a81-8351-969944a9d853',
    '82e8992b-a6ad-4133-8679-9da36156ef03',
    '7dfb315a-9496-4fae-88e4-c8b535fa1d41',
    'eaf28803-af41-468f-8c91-bd19706894a0',
    '7f269375-b692-4906-ab08-3a029bdef3a2',
    'd8bcbde3-2e0e-4298-ae92-cc052cff87d8',
    '79b4a92f-1d98-4e63-8f72-f4362b6a422c',
    '204878c7-434e-421b-9273-880274280414',
    '9306d258-0e76-4524-aba7-15d5cfa79b4e',
    '92c3b3a3-adeb-49b2-ab13-c679607e9416',
    'bcadc502-7867-41e8-a6ed-e837a6e3f5b9',
    '91eccd88-4292-47b0-8007-be43b3cfe23a',
    '514ba85f-6b3d-488b-877a-5f1097909b46',
    'b00d6329-a28b-4dab-af7e-f1e75ea0e583',
    'ea9308fd-c76c-4f2e-af4f-797ef9e76824',
    '08f82265-31ec-45ff-a9c2-36edda6091ae',
    'f77eeabe-6029-4c1f-9133-9b570e09655b',
    '2637156a-21f1-4c42-ad21-e4bfdf9d6fe5',
    'ffb54902-5e79-48fe-90d1-65d195646237',
    '0c7686e1-a029-4263-9a51-dbe80539b285',
    'fdf7e655-6dbe-4723-8ee4-f3b77c99b38d',
    '689aeea5-05d6-4671-b7b4-61e31dc1569c',
    'ce787f15-43a2-4f9f-8bef-9ae20b90c58e',
    'aeef1980-c474-479c-a04b-f06cb7e0c3f0',
    '5a3cee29-06a3-4515-a2e5-45e920d57d85',
    'f0d9da65-fd55-4009-a60b-d3d420fec6a3',
    '28ebf8d0-7062-47ad-b543-ae46273f1902',
    'a482f36c-b294-4fcb-af8a-307eea3ed359',
    '0c49f046-b902-4a8e-b9e3-1f59d46459d6',
    'd0688eec-19a0-4b1c-9339-f2ba9176f231',
    'a7049183-71dd-4e65-b1be-3aad9f42c5d5',
    '34b5c63c-7af7-416a-999f-a54f6f5efa6b',
    '8e0d1039-cf18-4087-803d-0f22b45f4f66',
    '70d84b65-7af5-4709-9e19-883341a01562',
    'f74e79e2-2dd1-4e89-9cb4-825cb0016b65',
    'f05d8b39-b50c-4ec0-93db-943130a95fdb',
    '955f4fcf-0dfe-40b0-9d86-038986666db9',
    '8268c27f-1a69-4d16-bc56-95ed59df1c4e',
    '6f542605-459a-473b-806f-0c94808840e8',
    '9f2d5f74-d810-4ad7-8214-b816c660056f',
    '234c2755-23d9-49a7-87f4-e4e5c8c7573a',
    'f6f39e1e-fbfd-4582-be54-eec7e7cd3872',
    '2af953e0-56a6-48e2-bc74-de4d185c7a08',
    'b78ffc7a-cc1a-415a-bb4f-83210d07df22',
    '4b0c7841-1f46-4447-b4d2-582909ff1b52',
    '8c799495-53f1-41f5-97ac-d7290c8cf71e',
    '3cbf263f-05fe-4ca9-b0c5-5c2becde85cb',
    '12499407-09f0-4add-8832-41721f4fe98c',
    'd43a40ef-76bb-4a03-b043-82ee7d784c49',
    'ca299e51-e8ed-4f04-a2a1-efd3071920e8',
    '8efd7803-89fe-4221-9c7d-6fc0fa0d0c4c',
    '5655093c-3bd1-4d83-a299-64ed6c841133',
    'da7d23b2-49b8-4858-8888-fa308073c110',
    '316f30e0-aafd-4755-b8e5-6e0fffcb076b',
    'a20faf51-b237-4a95-b6eb-c311b49f5b14',
    'e56ce513-05da-4755-b716-b1bed8972fa1',
    '95b717cc-6409-49b0-b61e-0d285ecc65c8',
    '44a7564f-a23e-4f3e-9ef6-56ca14eda8e1',
    'f059bfa0-20f1-4467-b7f1-b557e468ec35',
    'c3389f3e-43f8-41c8-a157-e9c53c2114db',
    '251f14b5-8cf8-457b-a7e9-dfb85bf7373b',
    '2a2b3e6a-d197-4d50-802a-248647400d91',
    'a0b1c9dd-fdb7-4bf1-b265-521b81824913',
    'be314581-df7e-45b6-a69f-61e483fcbffb',
    '2c1766b4-74f4-4274-9b01-2dbead352b5a',
    'b554d06d-cf57-424c-b85d-72ef4d2234ea',
    '5a2f5c4e-8a41-4ce6-a784-22ad887a93c5',
    '81d91512-f30e-4684-a120-5662c903b396',
    '5c738cc3-107a-4722-a075-637a0e01c54e',
    '77af34f8-292c-4246-a257-aad69ed6f99b',
    'fa868614-1e31-402d-90c7-33a68aa36fb1',
    'd23fa222-b0be-44a8-aeb1-e4801b741bae',
    '8977cd42-cd4d-4d8d-8f64-e146445ba662',
    'f3b161a3-deae-44d0-9b60-4ac1b400316d',
    '41dee2a0-0379-4549-8554-b0c6e4665954',
    '9e00e01d-a5ed-417b-b334-3ecaadcf56cf',
    '83404ab3-b507-4d35-af17-4c469c6ff6f6',
    '44794964-ffcb-4029-aba4-3944a56c9b15',
    '58e4b4f6-1234-4de8-b2b4-0faaed3945bf',
    'fb270636-76e8-42bf-87ec-1bdb3d4c2417',
    '436deadc-3451-4130-b83e-e6a2ac8aee7f',
    'a4ee667c-e665-41f2-aac2-872c023ac5e6',
    '08fb9757-4bd8-41ad-b2f3-0aa7f8246be2',
    'b3385ebe-63f9-4c5b-bdf6-617d6548d0ac',
    '634df2a2-d58f-4fc0-8109-0ac271016e36',
    '1db371fc-61cb-4aab-b974-04c0e8c27579',
    '559250e9-c972-42dd-a331-6d52c22bfe37',
    'c8809eca-27ce-4459-a4b8-e43f7b848e0b',
    '5693aae7-4f7b-47d2-af7e-8527a4a082b9',
    '1c25f9ca-de38-41fa-962b-ce41fe258235',
    '411ae7d1-38bf-4c52-888e-fe886350400e',
    '4effcda3-bb94-4a5d-bf74-9fc3bb5b5f0a',
    '8ad2c859-75e1-4585-bf6e-638d67b93574',
    '00603206-35bb-4eef-a20f-0cffbc1d7a58',
    '510ced9a-1de0-4f6e-93c0-7e1f0e3fb54f',
    'fa3305e1-0691-4118-af36-8056743b75c3',
    'cec0670b-9ec9-4be0-b7f3-0e3f2d5f3cc8',
    'f990caf2-ef35-47b0-b57e-a60be50bd7ca',
    '7963aa82-c7aa-43bc-90be-00c3db7b2a7e',
    '8ba41e4f-1fbc-4900-966c-f34d268bbc3b',
    'af0240ae-edf7-4bc2-b14d-89111cfead94',
    '49ce04cc-a79e-4196-ad86-20ad0a528939',
    'feaf236f-1e69-4216-9180-1f710e40592a',
    '3c470403-b6c5-4099-814d-20e832588aa7',
    '60817f79-76fd-4ee0-936d-18bb75f522bf',
    '541d4951-bd4e-4e0d-82f3-08355b7d220f',
    'a4878df0-72bc-4436-af7d-f020e117a060'
];
export default mitras 