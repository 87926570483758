import React, { useState, useEffect } from 'react';
import * as jobDemandsService from '../service/jobDemandsService';
import * as loginService from '../service/loginService';
import { useHistory } from 'react-router-dom';
import NavigationTab from './common/NavigationTab.component';
import JobDemandsTable from './JobDemandsTable.component';
const DEFAULT = 'DEFAULT';

const JobDemandsPage = () => {
	const [ mitra, setMitra ] = useState();
	const [ jobDemands, setJobDemands ] = useState([]);
	const [ validJobDemandsCities, setValidJobDemandsCities ] = useState([]);
	const [ validJobDemandsLocalities, setValidJobDemandsLocalities ] = useState([]);
	const [ validJobDemandsClients, setValidJobDemandsClients ] = useState([]);
	const [ validJobDemandsCategories, setValidJobDemandsCategories ] = useState([]);
	const [ jobDemandsVertical, setJobDemandsVertical ] = useState([]);
	const [ city, setCity ] = useState(DEFAULT);
	const [ client, setClient ] = useState(DEFAULT);
	const [ locality, setLocality ] = useState(DEFAULT);
	const [ category, setCategory ] = useState(DEFAULT);
	const [ vertical, setVertical ] = useState(DEFAULT);

	const history = useHistory();

	useEffect(() => {
		const getJobDemandsData = async (e) => {
			try {
				jobDemandsService.getJobDemandsData().then((result) => {
					if (result && result.data.viewData) {
						if(result.data.viewData.allJobDemands) {
							setJobDemands(result.data.viewData.allJobDemands);
						}
						if(result.data.viewData.validJobDemandsCities) {
							setValidJobDemandsCities(result.data.viewData.validJobDemandsCities);
						}
						if(result.data.viewData.validJobDemandsClients) {
							setValidJobDemandsClients(result.data.viewData.validJobDemandsClients);
						}
						if(result.data.viewData.validJobDemandsCategories) {
							setValidJobDemandsCategories(result.data.viewData.validJobDemandsCategories);
						}
					}
				});
			} catch (error) {
				console.log(error);
			}
		};
		getJobDemandsData();
		loginService
			.validateSession()
			.then((response) => {
				setMitra(response.data.mitra);
			})
			.catch((error) => history.push('/'));
	}, []);

	const getJobDemandsOnFiltersChange = async (city, locality, client, category, vertical) => {
		try {
			jobDemandsService.getJobDemandsOnFiltersChange(city, locality, client, category, vertical).then((result) => {
				if (result && result.data.viewData.allJobDemands) {
					setJobDemands(result.data.viewData.allJobDemands);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleLocalityChange = (e) => {
		setLocality(e.target.value);
		getJobDemandsOnFiltersChange(city, e.target.value, client, category, vertical);
	};

	const getJobDemandsOnCityChange = async (city) => {
		try {
			jobDemandsService.getJobDemandsOnCityChange(city).then((result) => {
				if (result && result.data.viewData) {
					if(result.data.viewData.validJobDemandsLocalities) {
						setValidJobDemandsLocalities(result.data.viewData.validJobDemandsLocalities);
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleCityChange = (e) => {
		setCity(e.target.value);
		getJobDemandsOnFiltersChange(e.target.value, locality, client, category, vertical);
		getJobDemandsOnCityChange(e.target.value);
	};

	const handleClientChange = (e) => {
		setClient(e.target.value);
		getJobDemandsOnFiltersChange(city, locality, e.target.value, category, vertical);
	};

	const getJobDemandsOnCategoryChange = async (category) => {
		try {
			jobDemandsService.getJobDemandsOnCategoryChange(category).then((result) => {
				if (result && result.data.viewData) {
					if(result.data.viewData.jobDemandsVertical) {
						setJobDemandsVertical(result.data.viewData.jobDemandsVertical);
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleCategoryChange = (e) => {
		setCategory(e.target.value);
		getJobDemandsOnFiltersChange(city, locality, client, e.target.value, vertical);
		getJobDemandsOnCategoryChange(e.target.value);
	};

	const handleVerticalChange = (e) => {
		setVertical(e.target.value);
		getJobDemandsOnFiltersChange(city, locality, client, category, e.target.value);
	};

	return (
		<div>
			<div className="month-filter">
				<select id="client-filter" value={client} onChange={handleClientChange} className="button Dropdown-control">
					<option value={DEFAULT} disabled>Client</option>
					{validJobDemandsClients.map((client) => (
						<option className="Dropdown-option" value={client.companyName}>
							{client.companyName}
						</option>
					))}
				</select>
				<select id="city-filter" value={city} onChange={handleCityChange} className="button Dropdown-control">
					<option value={DEFAULT} disabled>City</option>
					{validJobDemandsCities.map((city) => (
						<option className="Dropdown-option" value={city.city}>
							{city.city}
						</option>
					))}
				</select>
				<select id="locality-filter" value={locality} onChange={handleLocalityChange} className="button Dropdown-control">
					<option value={DEFAULT} disabled>Locality</option>
					{validJobDemandsLocalities.map((locality) => (
						<option className="Dropdown-option" value={locality.name}>
							{locality.name}
						</option>
					))}
				</select>
			</div>
			<div className="month-filter">
				<select id="category-filter" value={category} onChange={handleCategoryChange} className="button Dropdown-control">
					<option value={DEFAULT} disabled>Job Category</option>
					{validJobDemandsCategories.map((category) => (
						<option className="Dropdown-option" value={category.jobType}>
							{category.jobType}
						</option>
					))}
				</select>
				<select id="vertical-filter" value={vertical} onChange={handleVerticalChange} className="button Dropdown-control">
					<option value={DEFAULT} disabled>Vertical</option>
					{jobDemandsVertical.map((vertical) => (
						<option className="Dropdown-option" value={vertical.vertical}>
							{vertical.vertical}
						</option>
					))}
				</select>
			</div>
			<div className="form_control job-demands-table">
				<JobDemandsTable rows={jobDemands}></JobDemandsTable>
			</div>
			<div className="navigation-container">
				<NavigationTab currentTab={2} />
			</div>
		</div>
	);
};

export default JobDemandsPage;
