import React, { useState, useEffect } from 'react';
import Contact from './leads/Contact.component';
import Lead from './leads/Lead.component';
import ClipLoader from 'react-spinners/ClipLoader';
import {DebounceInput} from 'react-debounce-input';
import * as searchService from './../service/searchService';
import * as loginService from './../service/loginService';
import { useHistory } from 'react-router-dom';
import NavigationTab from './common/NavigationTab.component';
import { logEvent } from '../service/tracking';
import trackingConstants from '../constants/trackingConstants';

const SearchPage = () => {
	const [ searchText, setSearchText ] = useState('');
	const [ leads, setLeads ] = useState([]);
	const [ showContact, setShowContact ] = useState(false);
	const [ selectedLead, setSelectedLead ] = useState({});
	const [ initText, setInitText ] = useState(true);
	const [ loaded, setLoaded ] = useState(false);
	const [ mitra, setMitra ] = useState();

	const history = useHistory();

	useEffect(() => {
		loginService
			.validateSession()
			.then((response) => {
				setMitra(response.data.mitra);
			})
			.catch((error) => history.push('/'));
	}, []);

	const handleSearchInput = async (event) => {
		try {
			logEvent('EnterSearchText', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_SEARCH);
			setSearchText(event.target.value);
			const searchKey = event.target.value
			if(searchKey.length > 2) {
				setInitText(false);
				setLoaded(true);
				searchService
					.search(searchKey)
					.then((response) => {
					if (response) {
						logEvent('CandidatesFound', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_SEARCH);
						setLeads(response.data.viewData.leads);
						setLoaded(false);
					}
				});
			} else {
				setLeads([]);
				setInitText(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleClearSearch = async (event) => {
		try {
			setSearchText('');
			setLeads([]);
			setInitText(true);
			logEvent('ClearSearch', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_SEARCH);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div>
			<div className="month-filter">
				<div className="searchBar">
					<span className="search-icon">
						<img alt="Search" src="/image/icon_search.png"/>
					</span>
					<DebounceInput
						id="search-candidate-text-field"
						minLength={2}
						debounceTimeout={1000}
						placeholder="Search Candidates"
						onChange={handleSearchInput}
						value={searchText}
					/>
					<span className="clear-search">
						{initText ? <img alt="Clear Search" src="/image/icon_clear_search_disabled.png"/>
							: <img alt="Clear Search" src="/image/icon_clear_search.png" id="clear-search" onClick={handleClearSearch}/>
						}
					</span>
				</div>
			</div>
			{initText ? (
				<div className="init-message">
					Search by <b>candidate details</b> (name/phone number) or <b>team member details</b> (name/phone number)
				</div>
				) : null
			}
			<div className="leads-wrapper">
				{loaded && !initText ? (
					<div className="loader_clip">
						<ClipLoader size={100} />
					</div>
				):( leads.length === 0 && !initText ? (
					<div className="leads-error no-leads">
						Couldn’t find anyone with that name or number
					</div>
				) : (
					leads.map((lead) => (
						<Lead
							key={lead.id}
							lead={lead}
							setShowContact={setShowContact}
							setSelectedLead={setSelectedLead}
							fromSearchResult={true}
						/>
					))
				)) }
			</div>
			<div className="navigation-container">
				<NavigationTab currentTab={3} />
			</div>
			<div className="bottom-detail-wrapper">
				<Contact visible={showContact} setShowContact={setShowContact} lead={selectedLead} />
			</div>
		</div>
	);
};

export default SearchPage;
