import React from 'react';
import { useHistory } from 'react-router-dom';
import trackingConstants from '../../constants/trackingConstants';

import '../../css/navigation.css';
import { logEvent } from '../../service/tracking';

const NavigationTab = ({ currentTab, mitra }) => {
	const history = useHistory();

	const onNav = (tab) => {
		if (tab === 0) {
			history.push('/');
			logEvent('ClickHomeTab', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 1) {
			history.push('/leads');
			logEvent('ClickCandidatesTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 2) {
			history.push('/jobDemands');
			logEvent('ClickJobDemandsTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		// else if (tab === 3) {
		// 	history.push('/search');
		// 	logEvent('ClickSearchTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		// }
		else {
			history.push('/account');
			logEvent('ClickAccountTab', trackingConstants.EVENT_GROUP_PROFILE, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
	};

	return (
		<div className="navigation-wrapper">
			<div id="home-footer-icon" className="navigation-menu">
				<div className="nav-icon" onClick={(e) => onNav(0)}>
					{currentTab === 0 ? (
						<img alt="Doc not uploaded" src="/image/icon_botnav_home_active.png" />
					) : (
						<img alt="Doc not uploaded" src="/image/icon_botnav_home_inactive.png" />
					)}
				</div>
				<div className={"navigation-text " + (currentTab === 0 ? 'active-navigation-tab' : null)}>Home</div>
			</div>
			<div id="candidates-footer-icon" className="navigation-menu" onClick={(e) => onNav(1)}>
				<div className="nav-icon">
					{currentTab === 1 ? (
						<img alt="Doc not uploaded" src="/image/icon_botnav_candidates_active.png" />
					) : (
						<img alt="Doc not uploaded" src="/image/icon_botnav_candidates_inactive.png" />
					)}
				</div>
				<div className={"navigation-text " + (currentTab === 1 ? 'active-navigation-tab' : null)}>Candidates</div>
			</div>
			<div id="demand-hub-footer-icon" className="navigation-menu" onClick={(e) => onNav(2)}>
				<div className="nav-icon">
					{currentTab === 2 ? (
						<img alt="Doc not uploaded" src="/image/icon_botnav_demand_active.png" />
					) : (
						<img alt="Doc not uploaded" src="/image/icon_botnav_demand_inactive.png" />
					)}
				</div>
				<div className={"navigation-text " + (currentTab === 2 ? 'active-navigation-tab' : null)}>Demand Hub</div>
			</div>
			{/* <div id="search-footer-icon" className="navigation-menu" onClick={(e) => onNav(3)}>
				<div className="nav-icon">
					{currentTab === 3 ? (
						<img alt="Doc not uploaded" src="/image/icon_botnav_search_active.png" />
					) : (
						<img alt="Doc not uploaded" src="/image/icon_botnav_search_inactive.png" />
					)}
				</div>
				<div className={"navigation-text " + (currentTab === 3 ? 'active-navigation-tab' : null)}>Search</div>
			</div> */}
			<div id="account-footer-icon" className="navigation-menu" onClick={(e) => onNav(4)}>
				<div className="nav-icon">
					{currentTab === 4 ? (
						<img alt="Doc not uploaded" src="/image/icon_botnav_account_active.png" />
					) : (
						<img alt="Doc not uploaded" src="/image/icon_botnav_account_inactive.png" />
					)}
				</div>
				<div className={"navigation-text " + (currentTab === 4 ? 'active-navigation-tab' : null)}>Account</div>
			</div>
		</div>
	);
};

export default NavigationTab;
