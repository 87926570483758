import axios from 'axios';
import {AdditionalFormsURLEnum} from '../utils/utility';

export const getAllClientNames = async (city, jobCategory) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/form-io/getClientNames?city=${encodeURIComponent(city)}&jobCategory=${encodeURIComponent(jobCategory)}`;
  return axios.get(url, { withCredentials: true });
};

export const getRecommendedJobs = async (company, location, jobCategory, phoneNumber) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/getRecommendedJobs`;
  return await axios.get(url, {
    params: {companypreference: company, locationPreference: location, jobCategory, phoneNumber}, withCredentials: true
  });
};


export const submitJobs = async (submittedJob, basicFormData, mitra) => {

  if (submittedJob.companyName in AdditionalFormsURLEnum)
    return {
      checkAdditionalForm: true
    }


  const requestData = {
    submission: {
      data: {
        sourceName: "mitra-leader",
        sourceId: mitra.id,
        name: basicFormData.name,
        phoneNumber: basicFormData.phoneNumber,
        createApplication: true,
        jobId: submittedJob.jobid,
        jobDemandId: submittedJob.jobdemandid
      },
      currentPageData: {
        createApplication: true,
      }
    }
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
  return axios.post(url, requestData, {withCredentials: true});

}

export const uniquenessCheck = (submittedJob, basicFormData, mitra) => {
  const requestData = {
    submission: {
      data: {
          sourceName: "mitra-leader",
          sourceId: mitra.id,
          name: basicFormData.name,
          phoneNumber: basicFormData.phoneNumber,
          clientName: submittedJob.companyName,
          jobId: submittedJob.jobid,
          jobDemandId: submittedJob.jobdemandid,
          uniqueness: true,
          city: basicFormData.cityPreference
      },
      currentPageData: {
        uniqueness: true
      }
    }
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
  return axios.post(url, requestData, {withCredentials: true});
}

export const createJobRecommendationAction = (submittedJob, basicFormData, mitra) => {
  const requestData = {
    submission: {
      data: {
          sourceName: "mitra-leader",
          sourceId: mitra.id,
          name: basicFormData.name,
          phoneNumber: basicFormData.phoneNumber,
          clientName: submittedJob.companyName,
          jobId: submittedJob.jobid,
          jobDemandId: submittedJob.jobdemandid,
          createJobRecommendation: true  
      },
      currentPageData: {
        createJobRecommendation: true
      }
    }
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
  return axios.post(url, requestData, {withCredentials: true});
}