import React, { useState } from 'react';
import { Radio } from '@rmwc/radio';
import {DataTable, DataTableContent, DataTableCell, DataTableHeadCell, DataTableHead, DataTableRow, DataTableBody} from '@rmwc/data-table';
import '../css/jobsTable.css';

const JobsTable = ({ rows, onJobChange }) => {
	const [selected, setSelected] = useState({});
 
	return (
		<DataTable className="data-table">
		  <DataTableContent className="data-table-content">
			<DataTableHead>
			  <DataTableRow>
				<DataTableHeadCell className="data-th" hasFormControl>
				</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Role Name</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Base Salary</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Vertical</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Sub-Client</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Type</DataTableHeadCell>
			  </DataTableRow>
			</DataTableHead>
			<DataTableBody className="data-body">
			  {rows.map((v, i) => (
				<DataTableRow className="data-tr" key={i} selected={selected[i]}>
				  <DataTableCell id="basic-form-job-select" className="data-td" hasFormControl>
					<Radio
					  checked={selected === i ? true : false}
					  onChange={() => {
						setSelected(i);
						onJobChange(v);
					  }}
					/>
				  </DataTableCell>
				  <DataTableCell className="data-td">{v.role}</DataTableCell>
				  <DataTableCell className="data-td">
					{v.baseSalary}
				  </DataTableCell>
				  <DataTableCell className="data-td">{v.vertical}</DataTableCell>
				  <DataTableCell className="data-td">{v.subClient}</DataTableCell>
				  <DataTableCell className="data-td">
					{v.requirements ? v.requirements.jobPreference : ''}
				  </DataTableCell>
				</DataTableRow>
			  ))}
			</DataTableBody>
		  </DataTableContent>
		</DataTable>
	  );
};

export default JobsTable;
