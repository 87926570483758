import React, { useState } from 'react';
import '../../css/contact.css';
import ReactBottomsheet from 'react-bottomsheet';
import { logEvent } from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';
import useGAEventsTracker from '../../service/useGSEventsTracker';

const Contact = ({ visible, setShowContact, lead }) => {

	const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

	const [language, setlanguage] = useState('English')
	const message = [
		{type: "English", value: ''},
		{type: "Hindi", value: ''}
	]

	const capatalize = (name) => {
		let arr = name.split(' ')
		let returnStr = ''
		for(let i=0;i<arr.length;i++){
			returnStr += arr[i][0].toUpperCase() + arr[i].substring(1) + " ";
		}
		return returnStr;
	}
	return (
		<ReactBottomsheet visible={visible && lead} onClose={(e) => setShowContact(false)} appendCancelBtn={false}>
			
			<div className="bottom-sheet-container">
				<div className="bottom-sheet-heading">
					<div className="bottom-sheet-heading-text">Connect with Candidate</div>
					<div className="bottom-sheet-close">
					<i className="fas fa-times" onClick={() => {setShowContact(false)}}></i>
					</div>
				</div>
				<div className="bottom-sheet-contact-details-container">
					<div className="bottom-sheet-contact-options">
						<div className="bottom-sheet-index">
							<div className="index-number">1</div>
						</div>
						<div className="bottom-sheet-contact-text">
							<div className="call-lead-name">Call {lead ? lead.name ? capatalize(lead.name) : null : null}</div>
							<div className="lead-number"> +91 {lead.phoneNumber}</div>
						</div>
						<div className="bottom-sheet-contact-button">
						<a className="bottom-sheet-buttons-layout"
								href={`tel:+91-${lead.phoneNumber}`}
								onClick={(e) => {GAEventsTracker("CallCandidate"); logEvent('CallCandidate', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE)}}
						>
							<button className="call-now">
								<i className="fas fa-phone"></i>
								&nbsp;Call Now
							</button>
						</a>
						</div>
					</div>
					<div className="bottom-sheet-contact-options">
						<div className="bottom-sheet-index">
						<div className="index-number">2</div>
						</div>
						<div className="bottom-sheet-contact-text">
							<div className="message-lead-name">Whatsapp {lead ? lead.name ? capatalize(lead.name) : null : null}</div>
							<div className="lead-number">+91 {lead.phoneNumber}</div>
							<select style={{width: 'inherit'}} name="language" id="language">
								<option>Hindi</option>
								<option>English</option>
							</select>
						</div>
						<div className="bottom-sheet-contact-button">
						<a
							rel="noopener noreferrer"
							className="bottom-sheet-buttons-layout"
							href={`https://api.whatsapp.com/send?phone=+91${lead.phoneNumber}&text=Hi`}
							onClick={(e) => {GAEventsTracker("MessageCandidate"); logEvent('WhatsAppCandidate', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE)}}
							target="_blank"
							
						>
							<button className="call-now" >
								<i className="fab fa-whatsapp" style={{color: '#D34612'}}></i>
								&nbsp;
								<span style={{color: '#D34612'}}>Message</span>
							</button>
						</a>
						</div>
					</div>
				</div>
			</div>
		</ReactBottomsheet>
	);
};

export default Contact;
