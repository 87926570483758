import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { validateSession } from "../../service/loginService"
import NavigationTab from "../common/NavigationTab.component"
import MainLogoHeader from "../header/MainLogoHeader"
import "./../../css/accountPage.css"

const accountPagesEnum = {
    uploadDocuments: "Update Bank Passbook/Cheque",
    userManagement: "User Management",
    termsAndConditions: "Terms And Conditions",
    trainingSession: "View/Edit Training Session"
}

const AccountPage = (props) => {

    const history = useHistory()

    useEffect(() => {
		validateSession()
			.then((response) => {
                
			})
			.catch((error) => history.push('/'));
    })


    const handleAccountPages = (route) => {
        if(route === "termsAndConditions")
            return window.open("/termsAndConditions")
        history.push(`/account/${route}`)
    }
    
    return (
        <React.Fragment>
            <div className="AccountPage">
                <MainLogoHeader />
                {Object.keys(accountPagesEnum).map(el => {
                    return (
                    <div className="mt-5 row" key={el}>
                        <div className="col"></div>
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 bg_gray" onClick={handleAccountPages.bind(this, el)}>
                            <h5>{accountPagesEnum[el]}</h5> 
                            <h5>{`>`}</h5>
                        </div>
                        <div className="col"></div>
                    </div>
                    )
                })}
            </div>
            <div className="navigation-container">
                <NavigationTab currentTab={4} />
            </div>
        </React.Fragment>
    )
}

export default AccountPage