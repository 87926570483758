import axios from "axios"

export const checkMitraDuplicacy = (phoneNumber) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/duplicateCheck?phoneNumber=${encodeURIComponent(phoneNumber)}`)
}

export const sendOTPToPhone = (phoneNumber) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/sendOtp`, {phoneNumber})
}

export const mitraSignup = (otp, mitraInfo) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, {otp, mitraInfo}, {withCredentials: true})
}

export const postTrainingSessionDates = (data, mitra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: mitra.phoneNumber,
                name: mitra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
            },
            currentPageData: {

            }
        }
    }

    for(let key in data){
        if(key === "submit") continue;
        if(data[key]){
            requestData.submission.currentPageData[key] = data[key]
            requestData.submission.data[key] = data[key]
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/addProfileData`
    return axios.post(url, requestData, {withCredentials: true});
}