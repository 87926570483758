import React, {useCallback, useEffect, useState} from "react";
import {Formio} from "react-formio"
import * as loginService from '../../service/loginService';
import {useHistory} from "react-router-dom";
import {createApplication, getCandidateInfo, postAdditionalInfo, postCandidateDocuments, uniquenessCheck} from "../../service/additionalInfoService";
import {AdditionalFormsURLEnum as URLEnum} from "./../../utils/utility"
import "./../../css/additionalInfo1.css"


let docsSubmitCount = 0;

const AdditionalInfo1 = props => {
    const history = useHistory()
    const [mitra, setMitra] = useState()
    const [isSubscribed, setisSubscribed] = useState(true)
    const lead = useState(JSON.parse(localStorage.getItem("lead")))[0]
    const [sub, setSub] = useState({
        data: {}
    })
    const [viaNext, setViaNext] = useState(false)


    const options = {
        noAlerts: true,
        hooks: {
            beforeNext: async (currentPage, submission, next) => {
                let errors = [];
                try {
                    const res = await postAdditionalInfo(submission.data, mitra, lead)
                    for (let key in res.data) {
                        if (res.data[key] !== "200")
                            errors.push(`${key}: ${res.data[key]}`)
                    }
                } catch (error) {
                    // console.log(error)
                    errors = error.response ? [error.response.data.message] : ["something went wrong"]
                }


                if (errors.length <= 0) {
                    await fetchInfo()
                    setViaNext(true)
                    next()
                } else {
                    alert(errors.join("\n"))
                    next([])
                }
            },
            // beforeSubmit: async (submission, next) => {
            //     let errors = [];
            //     try {
            //         const route = props.location.route
            //         const res = await postCandidateDocuments(submission.data, mitra, lead, route)
            //         console.log(res)
            //         for(let key in res.data){
            //             if(res.data[key]!== "200")
            //                 errors.push({message: res.data[key]})
            //         }
            //     } catch (error) {
            //         // console.log(error)
            //         errors = error.response ? [{message: error.response.data.message}]: [{message: "something went wrong"}]
            //     }

            //     if(errors.length > 0 ){
            //         next(errors)
            //     }
            //     else{
            //         history.replace("/leads")
            //     }

            // },

            beforeCancel: () => {
                if (window.confirm('Are you sure you want to cancel this form?')) {
                    history.push("/leads")
                }
            }
        },
        breadcrumbSettings: {
            clickable: false
        }
    }


    const fetchForm = useCallback(async (formURL) => {
        Formio.createForm(document.getElementById("form-io"), formURL, options)
            .then(function (form) {
                form.on("change", async (submission) => {
                    const key = submission.changed ? submission.changed.component.key : undefined
                    if (key && submission.data[key] && Array.isArray(submission.data[key]) && submission.data[key].length > 0 && submission.data[key][0].data) {
                        submission.data[key][0].url = submission.data[key][0].data.imageUrl
                        form.submission = {
                            data: submission.data
                        }
                        let dummyData = {onBoardingPreference: submission.data['onBoardingPreference']}
                        dummyData[key] = submission.data[key];
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = true;
                        let errorStatus = await validateFile({data: dummyData});
                        if (errorStatus) {
                            const div = document.querySelector('.formio-component-' + key);
                            div.querySelector('.fa-remove').click()
                        }
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = false;
                    }
                })
                form.on("submit", async (submission) => {
                    const submitButtomEl = document.querySelector(".btn-wizard-nav-submit")
                    if (submitButtomEl) {
                        submitButtomEl.disabled = true;
                        submitButtomEl.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)

                    }
                    let errors = [];
                    // remove validation

                    // try {
                    //     const res = await postCandidateDocuments(submission.data, mitra, lead)
                    //     for(let key in res.data){
                    //         if(res.data[key]!== "200"){
                    //             let errMsg = res.data[key].split("Error: ").join("").split("500: ").join("")
                    //             const attemptsIndex = errMsg.indexOf("Attempt: ")

                    //             if(attemptsIndex > -1){
                    //                 errMsg = errMsg.split("Attempt: ").join("")
                    //                 const attempts = errMsg.split(" ")[0]
                    //                 if(!isNaN(attempts * 1) && attempts > 3 && docsSubmitCount >=3){}
                    //                 else{
                    //                     errMsg = errMsg.slice(errMsg.indexOf(" ") + 1)
                    //                     errors.push(`${key}: ${errMsg}`)
                    //                 }
                    //             }
                    //             else{
                    //                 errors.push(`${key}: ${errMsg}`)
                    //             }
                    //         }
                    //     }
                    // } catch (error) {
                    //     console.log(error)
                    //     if(error.response)
                    //         errors = error.response.data.message ? [error.response.data.message]: ["something went wrong"]
                    //     else
                    //         errors = ["something went wrong"]
                    // }
                    if (errors.length <= 0) {
                        const route = props.location.route

                        if (!(route && route === "jobRecommendationPage")) {
                            return history.replace("/leads")
                        }
                        try {

                            const createApplicationRes = await createApplication(mitra, lead)
                            if (createApplicationRes.data.createApplication !== "200") {
                                errors.push(createApplicationRes.data.createApplication)
                            } else {
                                await uniquenessCheck(JSON.parse(localStorage.getItem('lead')));
                                return history.replace("/leads")
                            }
                        } catch (error) {
                            if (error.response)
                                errors = error.response.data.message ? [error.response.data.message] : ["something went wrong"]
                        }
                    }
                    docsSubmitCount++
                    alert(errors.join("\n"))
                    if (submitButtomEl) {
                        submitButtomEl.disabled = false;
                        submitButtomEl.removeChild(submitButtomEl.lastChild)
                    }


                })
                form.submission = sub
                setTimeout(() => {
                    if (viaNext)
                        form.setPage(1)
                }, 10)

                setTimeout(() => {
                    document.querySelectorAll("img").forEach(el => {
                        el.addEventListener("click", (e) => {
                            window.open(e.target.getAttribute("src"))
                        })
                    })
                }, 1000)
            })
    }, [options, props, history, lead, mitra, viaNext])

    const fetchInfo = async () => {
        const populatedRes = await getCandidateInfo(lead.phoneNumber)
        const populatedData = {}
        populatedRes.data.forEach(el => {
            try {
                const parsedValue = JSON.parse(el.value)
                if (typeof parsedValue === "number")
                    populatedData[el.type] = `${parsedValue}`
                else
                    populatedData[el.type] = [{...parsedValue, originalName: parsedValue.url, storage: "url"}]
            } catch (error) {
                populatedData[el.type] = el.value
            }
        })

        if (populatedData["aadhaarCard"] && populatedData["aadhaarCard"][0].otherSideImageUrl)
            populatedData["aadhaarCard1"] = [{originalName: populatedData["aadhaarCard"][0].otherSideImageUrl, size: populatedData["aadhaarCard"][0].size, url: populatedData["aadhaarCard"][0].otherSideImageUrl, storage: "url"}]

        if (populatedData["drivingLicense"] && populatedData["drivingLicense"][0].otherSideImageUrl)
            populatedData["drivingLicense1"] = [{originalName: populatedData["drivingLicense"][0].otherSideImageUrl, size: populatedData["drivingLicense"][0].size, url: populatedData["drivingLicense"][0].otherSideImageUrl, storage: "url"}]

        if (populatedData["vehicleRc"] && populatedData["vehicleRc"][0].otherSideImageUrl)
            populatedData["vehicleRc1"] = [{originalName: populatedData["vehicleRc"][0].otherSideImageUrl, size: populatedData["vehicleRc"][0].size, url: populatedData["vehicleRc"][0].otherSideImageUrl, storage: "url"}]
        setSub({data: populatedData})
    }

    useEffect(() => {
        console.log(props);
        docsSubmitCount = 0;
        fetchInfo()
    }, [])


    useEffect(() => {
        if (!lead || !URLEnum[lead.companyName]) {
            history.replace("/leads")
        }
        else

            // if(lead.companyName !== "Dunzo" || (lead.companyCity !== "Bangalore" && lead.companyCity !== "Bengaluru" && lead.companyCity !== "Mumbai" && lead.companyCity !== "Chennai") )
            //     fetchForm(URLEnum[lead.companyName])
            // else {
            //     fetchForm(URLEnum["Dunzo_Bangalore_Mumbai"])
            // }
            fetchForm(URLEnum[lead.companyName])
      
        loginService
            .validateSession()
            .then((response) => {
                const loggedInMitra = response.data.mitra;
                // console.log(loggedInMitra)
                if (isSubscribed)
                    setMitra(loggedInMitra)
            })
            .catch((error) => {
                history.push({
                    pathname: '/',
                    state: {
                        redirectTo: '/leads'
                    }
                })
            });

        return () => {setisSubscribed(false);}

    }, [fetchForm, history, isSubscribed, lead])

    const validateFile = async (data) => {
        const res = await postCandidateDocuments(data.data, mitra, lead);
        let error = false;
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                let errMsg = res.data[key].split("Error: ").join("").split("500: ").join("")
                const attemptsIndex = errMsg.indexOf("Attempt: ")
                if (attemptsIndex > -1) {
                    errMsg = errMsg.split("Attempt: ").join("")
                    const attempts = errMsg.split(" ")[0]
                    if (!isNaN(attempts * 1) && attempts > 3 && docsSubmitCount >= 3) {}
                    else {
                        errMsg = errMsg.slice(errMsg.indexOf(" ") + 1)
                        alert(`${key}: ${errMsg}`);
                        error = true;
                    }
                }
                else {
                    alert(`${key}: ${errMsg}`);
                    error = true;
                }
            }
        }
        return error;
    }

    return (
        <div className="AdditionalInfo1">
            <h2 className="heading_1 add-job">
                {lead && lead.companyName} Additional Form
            </h2>
            {mitra && <div id="form-io"></div>}
        </div>
    )
}


export default AdditionalInfo1;