import React, { useState } from 'react';
import {DataTable, DataTableContent, DataTableCell, DataTableHeadCell, DataTableHead, DataTableRow, DataTableBody} from '@rmwc/data-table';
import '../css/jobsTable.css';

const JobDemandsTable = ({ rows }) => {
	const [selected, setSelected] = useState({});
    console.log(rows);
	return (
		<DataTable className="data-table">
		  <DataTableContent className="data-table-content">
			<DataTableHead>
			  <DataTableRow>
				<DataTableHeadCell className="data-th">Client</DataTableHeadCell>
				<DataTableHeadCell className="data-th">City</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Locality</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Job Category</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Vertical</DataTableHeadCell>
				<DataTableHeadCell className="data-th">Type</DataTableHeadCell>
				<DataTableHeadCell className="data-th">No. of requirements</DataTableHeadCell>
			  </DataTableRow>
			</DataTableHead>
			<DataTableBody className="data-body">
			  {rows.map((v, i) => (
				<DataTableRow className="data-tr" key={i} selected={selected[i]}>
				  <DataTableCell className="data-td">{v.companyName}</DataTableCell>
				  <DataTableCell className="data-td">{v.city}</DataTableCell>
				  <DataTableCell className="data-td">{v.name}</DataTableCell>
				  <DataTableCell className="data-td">{v.jobType}</DataTableCell>
				  <DataTableCell className="data-td">{v.vertical}</DataTableCell>
				  <DataTableCell className="data-td">
				  		{v.requirements ? v.requirements.jobPreference : ''}
				  </DataTableCell>
				  <DataTableCell className="data-td">{v.numOpenings}</DataTableCell>
				</DataTableRow>
			  ))}
			</DataTableBody>
		  </DataTableContent>
		</DataTable>
	  );
};

export default JobDemandsTable;
