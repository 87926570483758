import React, {useState, useEffect} from 'react';
import Lead from './Lead.component';
import NavigationTab from '../common/NavigationTab.component';
import Contact from '../leads/Contact.component';
// import ContactPopup from './Contact-popup.component';
import FilterPopup from './Filter-popup.component';
import CandidateProfilePopup from './candidateProfilePopup.component'
import '../../css/lead.css';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import * as loginService from '../../service/loginService';
import {useHistory} from 'react-router-dom';
// import {logEvent} from '../../service/tracking';
// import trackingConstants from '../../constants/trackingConstants';
import { CSVLink } from "react-csv"
import Moment from 'react-moment';
import moment from "moment";
// import Contact from './Contact.component';
import ReactGA from 'react-ga';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import useGAEventsTracker from '../../service/useGSEventsTracker';



const LeadsPage = () => {

	const getfirstDateOfMonth = () => {
		const date = moment();
			const firstDate = moment().startOf('month');
			return firstDate;
	}

	const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

	const [leads, setLeads] = useState([]);
	const [prevLeads, setPrevLeads] = useState([]);
	const [disableFilters, setDisableFilters] = useState(false);
	const [startDate, setStartDate] = useState(getfirstDateOfMonth());
	const [endDate, setEndDate] = useState(moment());
	const [focusedInput, setFocusedInput] = useState(null);
	const [showCategoryFilterPopup, setCategoryShowFilterPopup] = useState(false);
	const [showStatusFilterPopup, setStatusShowFilterPopup] = useState(false);
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [atRiskTab, setAtRiskTab] = useState(false);
	const [breadcrumb, setbreadcrumb] = useState('All Candidates')
	const [atRiskLeads, setAtRiskLeads] = useState([]);
	const [showDateChip, setShowDateChip] = useState(false)
	const [showWarningMessageAtRisk, setShowWarningMessageAtRisk] = useState(true)
	const [showContactBottomSheet, setShowContactBottomSheet] = useState(false)
	const [showBreadcrumb, setShowBreadcrumb] = useState(false);
	const [clientSelected, setClientSelcted] = useState([
		{label: 'Zomato', value: false},
		{label: 'Dunzo', value: false},
		{label: 'Swiggy', value: false},
		{label: 'Shadowfax', value: false},
		{label: 'Uber', value: false},
		{label: 'Rapido', value: false}
	])
	const [statusesSelected, setStatusesSelected] = useState([
		{label: 'Pending', value: false},
		{label: 'Non Unique', value: false},
		{label: 'Unique', value: false},
		{label: 'Document Pending', value: false},
		{label: 'Rejected', value: false},
		{label: 'Expired', value: false},
		// {label: 'Interested', value: 'Interested'},
		// {label: 'Created', value: 'Created'},
		// {label: 'Not Interested', value: 'Not Interested'},
		// {label: 'First Trip Completed', value: 'Order Started'},
		// {label: 'Invalid', value: 'Invalid'},
		// {label: 'Inactive', value: 'Inactive'},
		// {label: 'Onboarded', value: 'Onboarded'},
		// {label: 'Expired', value: 'Expired'},
		// {label: 'Onboarded', value: 'Joined'},
		// {label: 'Registered', value: 'Registered'},
		// {label: 'Declined', value: 'Declined'},
		// {label: 'Pending for Referral', value: 'undefined'},
		// {label: 'Signup', value: 'Signup'},
		// {label: 'Application', value: 'Application'},
		// {label: 'Qualified lead', value: 'Qualified lead'},
		// {label: 'App downloaded', value: 'App downloaded'},
		// {label: 'Profile completed', value: 'Profile completed,'},
		// {label: 'Training completed', value: 'Training completed'},
		// {label: 'VKYC completed', value: 'VKYC completed'},
		// {label: 'BGV Pending', value: 'BGV Pending'},
		// {label: 'BGV rejected', value: 'BGV rejected'},
		// {label: 'BGV Passed', value: 'BGV Passed'},
		// {label: 'First Milestone', value: 'First Milestone'},
		// {label: 'Second Milestone', value: 'Second Milestone'},
		// {label: 'Third Milestone', value: 'Third Milestone'},
	]);
	const history = useHistory();

	useEffect(() => {
		window.setTimeout(function () {
			if (window.location.href.indexOf('/leads') > -1) {
				window.location.reload();
			}
		}, 500000);
		
		loginService
			.validateSession()
			.then((response) => {
				setMitra(response.data.mitra);
				addFiltersData(clientSelected, moment(startDate).format("YYYY-MM-DD") , moment(endDate).add(1,'days').format("YYYY-MM-DD") , statusesSelected);
			})
			.catch((error) => history.push('/'));
	}, [atRiskTab, clientSelected, statusesSelected, endDate])

	const addFiltersData = (category, startDate, endDate, status) => {
		const data = new FormData();
		data.append('startDate', startDate);
		data.append('endDate', endDate);
		data.append('categoryOrClient', JSON.stringify(category));
		data.append('status', JSON.stringify(status));
		data.append('withJobs', true);
		let url = process.env.REACT_APP_BACKEND_URL + '/addFilters';
		setLoaded(false);
		let response = axios.post(url, data, {withCredentials: true});
		response.then(function (result) {
			if (result.data.leads) {
				if(!atRiskTab){
					setLeads(result.data.leads);
					// console.log('array :>> ', [...result.data.leads, ...leads]);
					modifyLeadArray(result.data.leads)
					setDisableFilters(false)
					document.getElementById('category-filter').style.background = '#FFF'
					document.getElementById('status-filter').style.background = '#FFF'
					document.getElementById('category-filter').style.color = 'rgba(0, 0, 0, 0.87)'
					document.getElementById('status-filter').style.color = 'rgba(0, 0, 0, 0.87)'
				}
				else if(atRiskTab){
					const DateObj = new Date();
					const atRiskLeads = []
					result.data.leads.map(data => {
						const leadRegisterDate = data.createdAt;
						const differnceInDates = (DateObj.getTime() - Date.parse(leadRegisterDate))/(1000 * 3600 * 24);
						if(differnceInDates >= 7 && differnceInDates <= 14 && ((data).companyName === "Shadowfax" || (data).companyName === "shadowfax_3pl")){
							atRiskLeads.push((data));
						}
					})
					document.getElementById('category-filter').style.background = '#F5F5F5'
					document.getElementById('status-filter').style.background = '#F5F5F5'
					document.getElementById('category-filter').style.color = 'rgba(0, 0, 0, 0.25)'
					document.getElementById('status-filter').style.color = 'rgba(0, 0, 0, 0.25)'
					setLeads(atRiskLeads);
					modifyLeadArray(atRiskLeads)
					setDisableFilters(true)
				}
				setLoaded(true);
				return result;
			} else {
				setLeads([]);
			}
		});
		return false;
	};


	// const getFiltersData = (mitra) => {
	// 	try {
	// 		const data = new FormData();
	// 		data.append('month', currentMonth);
	// 		data.append('withJobs', true);
	// 		let url = process.env.REACT_APP_BACKEND_URL + '/getFilters';
	// 		let response = axios.post(url, data, {withCredentials: true});
	// 		response.then(function (result) {
	// 			if (result) {
	// 				// setLeadsForStatusFilter(result.data.viewData.leads);
	// 				setLeads(result.data.viewData.leads);
	// 				setLoaded(true);
	// 				const Category = result.data.viewData.category;
	// 				const totalCategories = Category.map((c) => c.jobType);
	// 				// setCategories(totalCategories);
	// 			}
	// 		});
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// 	return false;
	// };

	// const getStatuses = (month, category) => {
	// 	const data = new FormData();
	// 	data.append('month', month);
	// 	data.append('categoryOrClient', category);
	// 	let url = process.env.REACT_APP_BACKEND_URL + '/getStatuses';
	// 	setLoaded(false);
	// 	let response = axios.post(url, data, {withCredentials: true});
	// 	response.then(function (result) {
	// 		if (result.data.statuses) {
	// 			let statuses = result.data.statuses;
	// 			statuses = statuses.map((c) =>
	// 				c.value === 'Not Uploaded' ?
	// 					c.label = 'Not Unique'
	// 					: c.value === 'Expired' ?
	// 						c.label = 'Lead Expired'
	// 						: c.value === 'Uploaded' ?
	// 							c.label = 'Unique'
	// 							: c.value === 'Joined' ?
	// 								c.label = 'Onboarded'
	// 								: c.value === 'Order Started' ?
	// 									c.label = 'First Trip Completed'
	// 									: c.label = c.label
	// 			)
	// 			// setStatuses(result.data.statuses);
	// 			setLoaded(true);
	// 			return result;
	// 		}
	// 	});
	// 	return false;
	// };

	// const handleJobTypeChange = (e) => {
	// 	setClientSelcted(e.target.value);
	// 	logEvent('FilterCandidatesByCategory', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE, {categorySelected: e.target.value});
		// addFiltersData(e.target.value, moment(startDate).format("YYYY-MM-DD") , moment(endDate).format("YYYY-MM-DD") , status);
	
		// addFiltersData(e.target.value, moment(startDate).format("YYYY-MM-DD") , moment(endDate).format("YYYY-MM-DD") , status, completeStatus);
		// getStatuses(month, e.target.value);
		// category, multi select
		// if(e.length > 0) {
		// let totalLeads = [];
		// const selectedCategories = e;
		// logEvent('FilterCandidatesByCategory', 
		// 	trackingConstants.EVENT_GROUP_VIEW_LEAD, 
		// 	trackingConstants.EVENT_SCREEN_CANDIDATE, 
		// 	{categorySelected: selectedCategories.map(category => category.value)});
		// for(let i = 0; i < selectedCategories.length; i++) {
		// 	if(selectedCategories[i].value === 'Show All') {
		// 		setLeads(leadsForStatusFilter);
		// 		return;
		// 	} else {
		// 		for(let j = 0; j < leadsForStatusFilter.length; j++) {
		// 			if(leadsForStatusFilter[j].categories.includes(selectedCategories[i].value)) {
		// 				if(totalLeads.indexOf(leadsForStatusFilter[j]) === -1){
		// 					totalLeads.push(leadsForStatusFilter[j]);
		// 				}
		// 			} else if(leadsForStatusFilter[j].currentCompany === selectedCategories[i].value){
		// 				if(totalLeads.indexOf(leadsForStatusFilter[j]) === -1){
		// 					totalLeads.push(leadsForStatusFilter[j]);
		// 				}
		// 			}
		// 		}
		// 		setLeads([]);
		// 		setLeads(totalLeads);
		// 		totalLeads = [];
		// 	}
		// }
		// }
	// };

	const deleteChip = (name, item) => {
		if(name === 'status'){
			let newStatus = [...statusesSelected]
			newStatus.map((single_status,index) => {
				if(single_status.label === item.label){
					single_status.value = false;
				}
			})
			setStatusesSelected(newStatus)
		}
		else if(name === 'category'){
			let newCategory = [...clientSelected]
			newCategory.map((single_category,index) => {
				if(single_category.label === item.label){
					single_category.value = false
				}
			})
			setClientSelcted(newCategory)
		}
		else if(name === 'date'){
			setStartDate(getfirstDateOfMonth())
			setEndDate(moment())
		}
	}

	

	// const handleStatusChange = (e) => {
	// 	logEvent('FilterCandidatesByStatus', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE);
	// 	const new_status = [...status];
	// 	setStatus(e.target.value);
	// };

	// const handleDocumentStatusChange = (e) => {
	// 	if (leadsForStatusFilter.length > 0 && e.length > 0) {
	// 		const totalLeads = [];
	// 		const selectedStatus = e;
	// 		logEvent('FilterCandidatesByDocuments',
	// 			trackingConstants.EVENT_GROUP_VIEW_LEAD,
	// 			trackingConstants.EVENT_SCREEN_CANDIDATE,
	// 			{documentsSelected: selectedStatus.map(status => status.value)});
	// 		for (let i = 0; i < selectedStatus.length; i++) {
	// 			if (selectedStatus[i].value === 'Show All') {
	// 				// setLeads(leadsForStatusFilter);
	// 				modifyLeadArray(leadsForStatusFilter)

	// 				return;
	// 			} else {
	// 				const filterImage = selectedStatus[i].value;
	// 				for (let j = 0; j < leadsForStatusFilter.length; j++) {
	// 					if (filterImage === 'Bank') {
	// 						if (!leadsForStatusFilter[j].documents.includes('Bank Passbook Image') &&
	// 							!leadsForStatusFilter[j].documents.includes('Bank Passbook or Cancelled Cheque Image') &&
	// 							!leadsForStatusFilter[j].documents.includes('Bank Cancelled Cheque Image')) {
	// 							if (totalLeads.indexOf(leadsForStatusFilter[j]) === -1) {
	// 								totalLeads.push(leadsForStatusFilter[j]);
	// 							}
	// 						}
	// 					} else {
	// 						if (!leadsForStatusFilter[j].documents.includes(filterImage)) {
	// 							if (totalLeads.indexOf(leadsForStatusFilter[j]) === -1) {
	// 								totalLeads.push(leadsForStatusFilter[j]);
	// 							}
	// 						}
	// 					}
	// 				}
	// 				// setLeads(totalLeads);
	// 				modifyLeadArray(totalLeads);
	// 			}
	// 		}
	// 	}
	// };

	const handleActiveTabStatus = (active, inactive) => {
		document.getElementById(inactive).style.color = "rgba(0, 0, 0, 0.85)"
		document.getElementById(inactive).style.borderBottomColor = "#F0F0F0"
		document.getElementById(active).style.fontWeight = 'normal'
		document.getElementById(active).style.color = "#D34612"
		document.getElementById(active).style.fontWeight = 'bold'
		document.getElementById(active).style.borderBottomColor = "#D34612"
		setStatusesSelected([]);
		setClientSelcted([]);
	}

	// code for CSV generator
	const headers = [
		{label: "Candidate Name", key: "Candidate Name"},
		{label: "Phone no.", key: "Phone no."},
		{label: "Client Name of the application", key: "Client Name of the application"},
		{label: "Application Date", key: "Application Date"},
		{label: "Activation Date", key: "Activation Date"}
	];

	let data = atRiskLeads.map(lead => {
		const localData = {
			"Candidate Name": lead.name,
			"Phone no.": lead.phoneNumber,
			"Client Name of the application": lead.companyName,
			"Application Date": lead.jobs[0].createdAtForGrouping,
			"Activation Date": lead.jobs[0].createdAtForGrouping
		}
		return localData;
	})

	const CSVDATA = {
		headers: headers,
		data: data,
		fileame: 'At_risk_applications.csv'
	};

	// csv generator ending	

	// const documentStatuses = [
	// 	{label: 'Show All', value: 'Show All'},
	// 	{label: 'Missing DL', value: 'DL Image'},
	// 	{label: 'Missing PAN', value: 'PAN Image'},
	// 	{label: 'Missing Aadhaar', value: 'Aadhaar Image'},
	// 	{label: 'Missing Bank document', value: 'Bank'},
	// 	{label: 'Missing Resume', value: 'Resume'},
	// 	{label: 'Missing RC Image', value: 'Vehicle RC Image'}
	// ];

	const modifyLeadArray = (leads) => {
		let newLeadArray = [];
		for (const lead of leads) {
			if (lead.companyName.toLowerCase() === 'shadowfax' && lead.status !== 'Created' && !lead.scheduledInterviewId) {

			} else {
				const index = newLeadArray.findIndex(ele => {
					return ele.phoneNumber == lead.phoneNumber
				})
				if (index > -1) {
					newLeadArray[index].jobs.push(lead);
					let d = newLeadArray[index].jobs.sort((a, b) =>
						new Date(a.createdAt) - new Date(b.createdAt)
					).map(el => el.string);
				} else {
					newLeadArray.push({name: lead.name, phoneNumber: lead.phoneNumber, companyName: lead.companyName, jobs: [lead]})
				}

			}
		}

		setLeads(newLeadArray);
	}
	const searchCandidate = (e) => {
		if(e.target.value == ''){
			setLeads(prevLeads);
			setPrevLeads([]);
			return;

		}
		if(!prevLeads.length){
			setPrevLeads(leads);
		}
		const searchResult = leads.filter(ele => {
			return ele.phoneNumber.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase());
		})
		setLeads(searchResult);
	}

	


	return (
		<div >
			<div className="header">
				<div className="header-text">
					<div className="breadcrumb-new" style={{color: '#D34612'}}>Candidates</div> 
					 <i className="fas fa-chevron-right icon-right Show-breadcrumb"></i>
					 <div className="breadcrumb-new Show-breadcrumb">{breadcrumb}</div>
					 {
						 showBreadcrumb ?
						  <i className="fas fa-chevron-right icon-right Show-breadcrumb"></i> : null
					 }
					 {
						 showBreadcrumb ?
						 <div className="breadcrumb-new Show-breadcrumb">Fiter Applied</div> : null
					 }
				</div>


				<div className="header-search">
					<input className="search-input" onInput={searchCandidate} type="text" placeholder="Search Candidates" />
					<div className="search-icon">
					<i className="fas fa-search"></i>
					</div>
				</div>
			</div>
			<div className="more-filters">
				<div className="filter-set-container">
					<div className="tool-tip">
						<DateRangePicker
							startDate={startDate}
							startDateId="s_id"
							endDate={endDate}
							endDateId="e_id"
							onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate); setShowDateChip(true) }}
							focusedInput={focusedInput}
							onFocusChange={e => setFocusedInput(e)}
							displayFormat="DD/MM/YYYY"
							isOutsideRange={(date) => {
								if (date > new Date(Date.now() + 86400000)) {
								return true;
								}
								 if(atRiskTab && date < (new Date(Date.now() - 1209600000))){ //if the date is before 14 days => disabled
									return true
								}
								 if(atRiskTab && date > (new Date(Date.now() - 518400000))){ // if the date is after previous 7th day from now
									return true
								}
								return false;
							}}
							numberOfMonths={1}
						/>
						<span className="tooltiptext">Application Date</span>
					</div>
					<div id="category-filter"
						 onClick={() => {
							 if(!disableFilters)setCategoryShowFilterPopup(true)
							 }} 
						 className=" Dropdown-control"
					>
						<div className="filter-label">Clients</div>
						<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
					</div>
				
					<div id="status-filter" onClick={() => {if(!disableFilters)setStatusShowFilterPopup(true)}} className="Dropdown-control">
						<div className="filter-label">Status</div>
						<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
					</div>
				</div>
				<div className="Chips-container">
					{
						(startDate !== null && endDate !== null && showDateChip) ? 
							<div className="single-chip"> 
								<span className="chip-text">
									<Moment format="D MMM" withTitle>{startDate}</Moment>
									{" - "}
									<Moment format="D MMM" withTitle>{endDate}</Moment>
								</span> 
								<span className="chip-cross" onClick={() => {deleteChip('date'); setShowDateChip(false)}}> <i className="fas fa-times"></i> </span> 
							</div>
						: null
					}

					{
						clientSelected.map((single_category) => {
							if(single_category.value){
								return 	<div className="single-chip"> 
											<span className="chip-text">{single_category.label}</span> 
											<span className="chip-cross" onClick={() => {deleteChip('category', single_category)}}> <i className="fas fa-times"></i> </span> 
										</div>	
							}
						})
					}

					{
						statusesSelected.map((single_status) => {
							if(single_status.value){
								return	<div className="single-chip"> 
											<span className="chip-text">{single_status.label}</span> 
											<span className="chip-cross" onClick={() => {deleteChip('status', single_status)}}> <i className="fas fa-times"></i> </span> 
										</div>	
							}
						})
					}
				</div>
			</div>
			
			<div className="tabs-container">
				<button id="allCandidates" 
					className="tab active-tab" 
					onClick={(e) => {
							setAtRiskTab(false);
							handleActiveTabStatus('allCandidates', 'atRiskApplication')
							setbreadcrumb("All Candidates")
							GAEventsTracker("OpenAllCandidatesTab")
							setStartDate(getfirstDateOfMonth()); setEndDate(moment()); 
							}}> 
					All Candidates
				</button>
				<button id="atRiskApplication" 
						className="tab"
						onClick={(e) => {
							setAtRiskTab(true);
							handleActiveTabStatus('atRiskApplication','allCandidates')
							setbreadcrumb("At Risk Applications")
							GAEventsTracker("OpenAtRiskTab")
							setStartDate(moment().subtract(14,'d')); setEndDate(moment().subtract(7,'d')); 
						}}> 
						<i className="fas fa-exclamation-circle" style={{color: '#D34612'}}> &nbsp; </i>
						Critical Candidates</button>
			</div>
			
			<div className="leads-wrapper">



			{
				atRiskTab && showWarningMessageAtRisk ? 
					<div className="warning-message-at-risk-container">
						<div className="warning-message-atrisk">
							<div style={{ width: '9%', lineHeight: 'inherit', textAlign: 'right',margin: 'auto'}}>
								<i className="fas fa-exclamation-circle" style={{color: '#D34612'}}> &nbsp; </i>
							</div>
							<div style={{ width: '82%', textAlign: 'justify'}}>
								You can view the list of Shadowfax candidates that have not taken any trips even after 7 days since activation in this section.
							</div>
							<div style={{ textAlign: 'center', alignItems: 'center',margin: 'auto'}}>
								<i className="fas fa-times icon-cross" onClick={() => {setShowWarningMessageAtRisk(false)}}></i>
							</div>
						</div>
					</div>
				: null

			}


			{
				(mitra) ? 
					(mitra.role === 'admin') ?
						atRiskTab ? 
							(leads.length > 0) ? 
								<div className="export-file-container">
									<div className="export-list">Export List(1)</div>
									<div className="download-file-container">
										<button className="download-file-button" onClick={() => {setAtRiskLeads(leads); GAEventsTracker("DownloadCSV")}}>
											<CSVLink {...CSVDATA} style={{color: "#D34612", textDecoration: 'none'}}>
												<i className="fas fa-download"></i>
												&nbsp;
												Download File
											</CSVLink>
										</button>
									</div>
								</div>
							: null 
						: null 
					: null
				:null
			}

				{loaded ? leads.length === 0 ? (
					<div className="leads-error">No leads found</div>
				) : (
					leads.map((lead, index) => (
						<Lead
							key={index}
							lead={lead}
							setShowDetail={setShowDetail}
							setSelectedLead={setSelectedLead}
							setShowContactBottomSheet={setShowContactBottomSheet}
						/>
					))
				) : (
					<div className="loader_clip">
						<ClipLoader size={100} />
					</div>
				)}
				<br />
			</div>
			<div className="bottom-detail-wrapper">
				<FilterPopup heading="Select Application Status" visible={showStatusFilterPopup} setShowFilterPopup={setStatusShowFilterPopup} setCategory={setStatusesSelected} list={statusesSelected} setShowBreadcrumb={setShowBreadcrumb} />
				<FilterPopup heading="Select Client" visible={showCategoryFilterPopup} setShowFilterPopup={setCategoryShowFilterPopup} setCategory={setClientSelcted} list={clientSelected} setShowBreadcrumb={setShowBreadcrumb} />
				<Contact visible={showContactBottomSheet} setShowContact={setShowContactBottomSheet} lead={selectedLead} />
				<CandidateProfilePopup visible={showDetail} setShowDetail={setShowDetail} lead={selectedLead}></CandidateProfilePopup>
			</div>
			<div className="navigation-container">
				<NavigationTab currentTab={1} />
			</div>
			
		</div>
	);
};

export default LeadsPage;
