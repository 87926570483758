import axios from 'axios';

export const getDataFromEnums = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getDataFromEnums`;
    return axios.get(url, { withCredentials: true });
};

export const createOrUpdate = async (data) => {
    const formData = new FormData();
    delete data.data.container.experienceLevel;
    delete data.data.container.englishLevels;
    delete data.data.container.educationLevel;
    delete data.data.container.salaryLevel;
    for (const [key, value] of Object.entries(data)) {
        formData.set(key, JSON.stringify(value));
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/lead/additionalInfo';
    return axios.post(url, formData, { withCredentials: true });
};

export const get = async (scheduledInterviewId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/lead/additionalInfo/${scheduledInterviewId}`;
    return axios.get(url, { withCredentials: true });
};

export const postAdditionalInfo = async (data, mitra, extra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: extra.phoneNumber,
                name: extra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
            },
            currentPageData: {

            }
        }
    }

    for(let key in data){
        if(data[key] && !Array.isArray(data[key])){
            requestData.submission.currentPageData[key] = data[key]
            requestData.submission.data[key] = data[key]
        }
    }
    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    // return axios.post(url, requestData, {withCredentials: true});
}

export const postCandidateDocuments = async (data, mitra, extra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: extra.phoneNumber,
                name: extra.name,
                sourceName: mitra.name,
                sourceId: mitra.id
            },
            currentPageData: {}
        }
    }

    let docs = false;

    for(let key in data){
        if(Array.isArray(data[key])){
            docs = true
            break;
        }
    }

    if(docs){
        for(let key in data){
            if(Array.isArray(data[key]) && data[key].length > 0){
                requestData.submission.data[key] = {url: data[key][0].data ? data[key][0].data.imageUrl: data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size}
                requestData.submission.currentPageData[key] = {url: data[key][0].data ? data[key][0].data.imageUrl: data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size}
            }
        }
    }
    else {
        for(let key in data){
            if(data[key] && !Array.isArray(data[key])){
                requestData.submission.data[key] = data[key]
                requestData.submission.currentPageData[key] = data[key]
            }
        }
    }

    if(data["aadhaarCard1"] && data["aadhaarCard1"].length > 0){
        requestData.submission.data["aadhaarCard"] = {...requestData.submission.data["aadhaarCard"], otherSideImageUrl: data["aadhaarCard1"][0].data ? data["aadhaarCard1"][0].data.imageUrl: data["aadhaarCard1"][0].url}
        delete requestData.submission.data["aadhaarCard1"]
        delete requestData.submission.currentPageData["aadhaarCard1"]
    }

    if(data["drivingLicense1"] && data["drivingLicense1"].length > 0){
        requestData.submission.data["drivingLicense"] = {...requestData.submission.data["drivingLicense"], otherSideImageUrl: data["drivingLicense1"][0].data ? data["drivingLicense1"][0].data.imageUrl: data["drivingLicense1"][0].url}
        delete requestData.submission.data["drivingLicense1"]
        delete requestData.submission.currentPageData["drivingLicense1"]
    }

    if(data["vehicleRc1"] && data["vehicleRc1"].length > 0){
        requestData.submission.data["vehicleRc"] = {...requestData.submission.data["vehicleRc"], otherSideImageUrl: data["vehicleRc1"][0].data ? data["vehicleRc1"][0].data.imageUrl: data["vehicleRc1"][0].url}
        delete requestData.submission.data["vehicleRc1"]
        delete requestData.submission.currentPageData["vehicleRc1"]
    }

    console.log(requestData)

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}

export const createApplication = (mitra, extra) => {
    const requestData = {
        submission: {
          data: {
              sourceName: "mitra-leader",
              sourceId: mitra.id,
              name: extra.name,
              phoneNumber: extra.phoneNumber,
              createApplication: true,
              jobId: extra.jobId,
              jobDemandId: extra.jobDemandId
          },
          currentPageData: {
            createApplication: true,
          }
        }
      }
    
      const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
      return axios.post(url, requestData, {withCredentials: true});
}

export const uniquenessCheck = (data) => {
    const requestData = {
      submission: {
        data: {
            name: data.name,
            phoneNumber: data.phoneNumber,
            clientName: data.companyName,
            uniqueness: true,
            city: data.companyCity
        },
        currentPageData: {
          uniqueness: true
        }
      }
    }
  
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
  }
  

export const getCandidateInfo = async (phoneNumber) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/getCandidateInfo?phoneNumber=${encodeURIComponent(phoneNumber)}`, {withCredentials: true})
}