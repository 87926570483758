import React, { useState } from 'react';
import { Button } from '@rmwc/button';

const UploadPeriscopeData = () => {
  const [periscopeFile, setPeriscopeFile] = useState(null);
  const [leadsAttachment, setLeadsAttachment] = useState(null);
  const [leadsEmail, setLeadsEmail] = useState(null);
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const xhr = new XMLHttpRequest();
    formData.append('files[]', periscopeFile, periscopeFile.name);
    formData.append('files[]', leadsAttachment, leadsAttachment.name);
    formData.append('files[]', leadsEmail, leadsEmail.name);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          alert(xhr.response);
        } else {
          alert(xhr.response);
        }
      }
    };
    let url = process.env.REACT_APP_BACKEND_URL + '/postPeriscopeData';
    xhr.open('POST', url);
    xhr.send(formData);
  };

  return (
    <div className="Home">
      <h2 className="heading_1 add-job">Vahan Jobs</h2>
      <h4 className="heading_1 form-sub-heading">Upload Data</h4>
      <form onSubmit={onSubmit}>
        <div className="form-control">
          <div className="input-text-title field-required">Periscope Data</div>
          <input type="file" accept=".xlsx" onChange={(e) => setPeriscopeFile(e.target.files[0])} />
        </div>
        <div className="form-control">
          <div className="input-text-title field-required">Leads Attachment (API)</div>
          <input
            type="file"
            accept=".xlsx"
            onChange={(e) => setLeadsAttachment(e.target.files[0])}
          />
        </div>
        <div className="form-control">
          <div className="input-text-title field-required">Leads Email (Non API)</div>
          <input type="file" accept=".xlsx" onChange={(e) => setLeadsEmail(e.target.files[0])} />
        </div>
        <div className="form-control">
          <Button raised label="Submit" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default UploadPeriscopeData;
