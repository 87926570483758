import React, {useEffect, useState} from "react"
import {Formio} from "react-formio"
import {submitBasicInfoForm} from "../service/homeService"
import JobRecommendationaPage from "./JobRecommendationPage"

import '../css/basicInfo.css'

const BasicInfoForm = ({mitra, name, phoneNumber, alternatePhoneNumber}) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formData, setFormData] = useState({})


    const options = {
        hooks: {
            beforeSubmit: async (submission, next) => {
                let errors = [];
                try {
                    const res = await submitBasicInfoForm(submission.data, mitra)
                    for (let key in res.data) {
                        if (res.data[key] !== "200" && key != 'clientPreference')
                            errors.push({message: res.data[key]})
                    }
                } catch (error) {
                    if (error.response)
                        errors = error.response.data.message ? [{message: error.response.data.message}] : [{message: "something went wrong"}]
                }

                if (errors.length > 0) {
                    next(errors)
                }
                else {
                    setFormData(submission.data)
                    setFormSubmitted(true)
                }

            }
        }
    }

    useEffect(() => {
        const plugin = {
            priority: 0,
            requestOptions: function (value) {
                value.credentials = 'include';
                return value;
            }
        };

        document.addEventListener('showAlternatePhoneNumber', (e) => {
            console.log(e);
        })
        const loadForm = async () => {
            await Formio.createForm(document.getElementById("form-io"), process.env.REACT_APP_FORM_IO_BASIC_INFO_FORM_URL, options).then(form => {
                form.submission = {
                    data: {
                        phoneNumber,
                        name,
                        alternatePhoneNumber
                    }
                }
            })

            if (Formio.plugins.length === 0)
                Formio.registerPlugin(plugin, "enableCredentials")
        }
        loadForm()
        setTimeout(() => {
            const allAlternative = document.getElementsByClassName('showAlternatePhoneNumber');
            if (allAlternative.length) {
                allAlternative[0].addEventListener('click', () => {
                    console.log('cicked');
                    allAlternative[0].style.display = "none";
                    document.getElementsByClassName('alternatePhoneNumber')[0].style.display = "block";


                })
            }
            const showLocationPreference2 = document.getElementsByClassName('showLocationPreference2');
            if (showLocationPreference2.length) {
                showLocationPreference2[0].addEventListener('click', () => {
                    showLocationPreference2[0].style.display = "none";
                    document.getElementsByClassName('locationPreference2')[0].style.display = "block";
                })
            }

            const showLocationPreference3 = document.getElementsByClassName('showlocationPreference3');
            if (showLocationPreference3.length) {
                showLocationPreference3[0].addEventListener('click', () => {
                    showLocationPreference3[0].style.display = "none";
                    document.getElementsByClassName('locationPreference3')[0].style.display = "block";
                })
            }
        }, 2000);
        return () => {Formio.plugins = []}

    }, [options])


    let content;
    if (!formSubmitted) {
        content = (
            <div className="parentFormClass">
                {/* <h4 className="heading_1 form-sub-heading">Basic Details</h4> */}
                <div id="form-io"></div>
            </div>
        )
    }
    else {
        content = (
            <JobRecommendationaPage mitra={mitra} basicFormData={formData} />
        )
    }

    return content
}

export default BasicInfoForm