import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { logEvent } from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';
import '../../css/lead.css';
import useGAEventsTracker from '../../service/useGSEventsTracker';

const overlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.4)'
}

const contentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  height: '550px',
  width: '400px',
  border: '1px solid #ccc',
  background: '#fff',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  borderRadius: '4px',
  outline: 'none',
  padding: '20px'
}

const graphItems = [
  { title: "Application Date", text: `Created on . `, id_icon: "ApplicationDate1", id_text: "ApplicationDate2" },
  { title: "Client Name", text: " ", id_icon: "ClientName1", id_text: "ClientName2" },
  { title: "Candidate Unique", text: "Unique ID" },
  { title: "Documents Upload", text: "Upload Documents" },
  { title: "Client App Download", text: "Downloaded on . " },
  { title: "Onboarding and Training", text: "Training Done on . " },
  { title: "1st Trip", text: "Not Yet Started" },
  { title: "10th Trip", text: "NA" },
  { title: "Incenetive Initiated", text: "NA" }
]

const show = (item, lead) => {
  if (item.title === "Application Date" || item.title === "Client Name") {
    return true;
  }
  else if (item.title === "Candidate Unique" && lead && (lead.companyName === 'Shadowfax' || lead.companyName === 'Shadowfax_3pl') && lead.status === "Created") {
    return true
  }
  else if (item.title === "Candidate Unique" && lead && lead.companyName === 'zomato' && lead.status === "Uploaded") {
    return true
  }
};

const CandidateProfilePopup = ({ visible, setShowDetail, lead }) => {

  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

  const checkAtRisk = (date, companyName, status) => {
    const DateObj = new Date();
    const differnceInDates = (DateObj.getTime() - Date.parse(date)) / (1000 * 3600 * 24);
    if (differnceInDates >= 7 && differnceInDates <= 14 && companyName === "Shadowfax" && status === "Ativated") {
      return true
    }
    return false
  }

  return (

    <Modal
      isOpen={visible && lead}
      onRequestClose={(e) => setShowDetail(false)}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: overlayStyle,
        content: contentStyle
      }}
      scrollable={true}
    >
      <div className="details-component-container">
        <div className="Contact-details-header">
          <div className="Candidate-details">
            <div className="head-details">
              <div className="user-image" >{lead ? lead.name ? lead.name.charAt(0).toUpperCase() : null : null}
                {
                  lead ? lead.jobs ? checkAtRisk(lead.jobs[0].createdAt, lead.companyName, lead) ? <div className="counter counter-lg"><i className="fas fa-exclamation"></i></div> : null : null : null
                }
              </div>
              <div className="name-number-container">
                <div className="lead-name">{lead.name}</div>
                <div className="lead-number">{lead.phoneNumber}</div>
              </div>
            </div>
          </div>
          <div className="close-popup" onClick={() => setShowDetail(false)}>
            <i className="far fa-times-circle"></i>
          </div>
        </div>
        <div className="flow-diagram">
          {
            graphItems.map((item, index) =>
              <div key={index} className="single-path" >
                <div className="symbol" >
                  <div className="circle-symbol">
                    <i className="far fa-check-circle" style={{ color: show(item) ? "#D34612" : "rgba(0, 0, 0, 0.45)" }}></i>
                  </div>
                  {
                    (graphItems.length !== index + 1) ? <div id={item.id_icon} className="pipe-symbol" style={{ color: show(item) ? "#D34612" : "rgba(0, 0, 0, 0.45)" }}>|</div> : null
                  }
                </div>
                <div className="single-path-data">
                  <div className="path-title" style={{ color: show(item, lead) ? "black" : "rgba(0, 0, 0, 0.45)", fontWeight: show(item, lead) ? "bold" : "none" }}>{item.title}</div>
                  <div className="path-text">{item.text}
                    {
                      (item.text === ' ') ? <span>{lead.companyName}</span> : null
                    }
                    {
                      (item.text === "Created on . ") ? <span>{lead ? lead.jobs ? lead.jobs[0].createdAtForGrouping : null : null}</span> : null
                    }
                  </div>
                </div>
                {
                  (item.title === "Client Name") ? <div style={{ width: '45%', textAlign: 'right' }}><img style={{ width: '50%', height: '100%' }} src={"/image/" + lead.companyName + ".png"}></img></div> : null
                }
              </div>
            )
          }
        </div>
        <div className="contact-buttons">
          <a
            rel="noopener noreferrer"
            // style={{textDecoration:"none",color: "#FFFFFF", width: '100%', height: '100%'}}
            href={`https://api.whatsapp.com/send?phone=+91${lead.phoneNumber}&text=Hi`}
            onClick={(e) => {GAEventsTracker("MessageCandidate"); logEvent('WhatsAppCandidate', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE)}}
            target="_blank"
            className="message-button"
          >
            <button >
              <i className="fab fa-whatsapp" style={{ color: '#D34612' }}></i>
              &nbsp;
              <span style={{ color: '#D34612' }}>Message</span>
            </button>
          </a>

          &nbsp;

          <a
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.85)", width: '100%', height: '100%' }}
            href={`tel:+91-${lead.phoneNumber}`}
            onClick={(e) =>  {GAEventsTracker("CallCandidate"); logEvent('CallCandidate', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE)}}
          >
            <button className="call-now">
              <i className="fas fa-phone"></i>
              &nbsp;Call Now
            </button>
          </a>
        </div>
      </div>

    </Modal>
  )
}
export default CandidateProfilePopup;