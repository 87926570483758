import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom"
import { ClipLoader } from "react-spinners";
import { validateSession } from "../../service/loginService"
import { getMitraProfileData } from '../../service/uploadMitraDocsService'
import TraingSessionForm from '../signup/TraingSessionForm';

export default function TrainingSessionPage() {

    const [mitra, setMitra] = useState(null)
    const [mitraInfo, setMitraInfo] = useState(null)
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    useEffect(() => {
		validateSession()
			.then((response) => {
                setMitra(response.data.mitra)
                return getMitraProfileData(response.data.mitra.phoneNumber)
			})
            .then(mitraInfoRes => {
                console.log(mitraInfoRes.data)
                setMitraInfo(mitraInfoRes.data)
                setLoading(false)
            })
			.catch((error) => history.push('/'));
    }, [history])

    return loading ? <div style={{marginTop: "100px"}}><ClipLoader size={100} /></div> : (
        <div>
            <TraingSessionForm mitra={mitra} mitraInfo={mitraInfo} />
        </div>
    )
}
