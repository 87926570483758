import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
// import { logEvent } from '../../service/tracking';
// import trackingConstants from '../../constants/trackingConstants';
import '../../css/contact.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '400px',
    width: '400px',
    padding: '20px 40px'
  },
};

const FilterPopup = ({ heading, visible, setShowFilterPopup, setCategory, list, setShowBreadcrumb }) => {

  const [stateList, setStateList] = useState(list)
  const selected = (index) => {
    const new_list = [...stateList];
    new_list[index].value = !new_list[index].value;
    setStateList(new_list)
  }

  const showBreadcrumb = () => {
      stateList.map(single_client => {
        if(single_client.value === true){
          setShowBreadcrumb(true)
          console.log("done")
        }
      })
  }

  return (
    
      <Modal
      isOpen={visible}
      onRequestClose={(e) => {setShowFilterPopup(false); setCategory(stateList); showBreadcrumb()}}
      shouldCloseOnOverlayClick={true}
      style={customStyles}
      ariaHideApp={false}
      >
        <div className="filter-pop-up-container">
          <div className="filter-popup-heading-container">
            <div className="select-client">{heading}</div>
            <div className="filter-popup-cross-icon"> 
              <i className="fas fa-times" onClick={() => {setShowFilterPopup(false); setCategory(stateList); showBreadcrumb()}}></i>
            </div>
          </div>
          <div className="popup-list-container">
            {
              stateList.map((item, index) => {
                return <div key={index} className="single-filter-item">
                  <input type='checkbox' onChange={() => {selected(index)}} checked={item.value} />
                    <label style={{padding: '1px 10px'}}>{item.label}</label>
                </div>
              })
            }
          </div>
        </div>   
      </Modal>
  )
}
export default FilterPopup;