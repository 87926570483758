import axios from 'axios';

export const postUserInfoForm = async (name, phoneNumber, status, role, email, managerId) => {
    const data = new FormData();
    data.append('name', name);
    data.append('phoneNumber', phoneNumber);
    data.append('status', status);
    data.append('role', role);
    data.append('email', email);
    data.append('managerId', managerId);
    let url = process.env.REACT_APP_BACKEND_URL + '/postUserInfoForm';
    return axios.post(url, data, {withCredentials: true});
};

export const getMitrasByManager = async () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/getMitraByManagerId';
    return axios.get(url, {withCredentials: true});

}
export const updateMitrasById = async (data) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/update';
    return axios.post(url, data, {withCredentials: true});

}