import React, { useState } from 'react';
import { Button } from '@rmwc/button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';

var template ="";
const UploadOnBoardingData = () => {
  const [onBoardFile, setOnBoardFile] = useState(null);
  const [ loading, setloading ] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('files[]', onBoardFile, onBoardFile.name);
    let url = process.env.REACT_APP_BACKEND_URL + '/postOnboardingData';
    if (template !=="") formData.append('template', template);
    setloading(true);
    axios({
      method: 'POST',
      url: url,
      data: formData,
      processData: false,
      contentType: false,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(function (response) {
        setloading(false);
        if (response.data.status) {
          alert('data saved');
        } else {
          alert(response.data.error);
        }
        window.location.reload();
      })
      .catch(function (response) {
        setloading(false);
        console.log(response.data);
      });
  };

  return (
    <div className="Home OnBoarding"  style={loading ? {pointerEvents: "none", opacity: "0.4"} : {}}>
      <h2 className="heading_1 add-job">Vahan Jobs</h2>
      <h4 className="heading_1 form-sub-heading">Upload OnBoarding Data</h4>
      <form onSubmit={(e) => onSubmit(e)}>
        <Dropdown />
        <div className="form-control">
          {loading ? <ClipLoader size={50} css={{'z-index':10, margin: 'auto'}} /> : ""}
        </div>
        <div className="form-control">
          <div className="input-text-title field-required">Upload File (Only .csv)</div>
          <input
            type="file"
            accept=".csv"
            onChange={(e) => setOnBoardFile(e.target.files[0])}
            required
          />
        </div>
        <div className="form-control">
          <Button raised label="Submit" type="submit" />
        </div>
      </form>
    </div>
  );
};

class Dropdown extends React.Component {
  state = {
    Companies: [],
    templates:[]

  };

  componentDidMount() {
    let url = process.env.REACT_APP_BACKEND_URL + '/getOBDataCompanies';
    fetch(url, { method: 'GET' })
      .then((response) => response.json())
      .then((response) => this.setState({ Companies: response.Companies }));
  }

  setTemplate(value){
    this.setState({templates:value.templates});
    template="";
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-control">
          <div className="input-text-title field-required">Company List</div>
          <Autocomplete
            onChange={(event, value) => this.setTemplate(value) }
            style={{ width: 400, textAlign: 'left', margin: '2rem 0 0.5rem 39%' }}
            options={this.state.Companies}
            autoHighlight
            getOptionLabel={(option) => option.key}
            renderOption={(option) => <React.Fragment>{option.key}</React.Fragment>}
            renderInput={(params) => (
            <TextField
              {...params}
              label=""
              name="company"
              variant="outlined"
              fullWidth
              required
            />
            )}
          />
        </div>
        {(this.state.templates.length >0) ?<div className="form-control">
          <div className="input-text-title field-required">Template List</div>
              <Autocomplete
                onChange={(event, value) => template = value.key}
                style={{ width: 400, textAlign: 'left', margin: '2rem 0 0.5rem 39%' }}
                options={this.state.templates}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(option) => <option key={option.key} value={option.key}>{option.label}</option> }
                renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  fullWidth
                  required
              />
            )}
          />
        </div>:""}
      </React.Fragment>
    );
  }
}

export default UploadOnBoardingData;
