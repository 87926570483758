import React, {useState} from 'react';
// import {withStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
// import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
// import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import '../../css/lead.css';
import {logEvent} from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';
// import {BrowserRouter as Route, Router, Redirect} from 'react-router-dom';
import {AdditionalFormsURLEnum} from '../../utils/utility';
import Modal from 'react-modal';
import useGAEventsTracker from '../../service/useGSEventsTracker';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


Modal.setAppElement('#root')

// const ExpansionPanel = withStyles({})(MuiExpansionPanel);
// const ExpansionPanelSummary = withStyles({
//   root: {
//     borderBottom: '1px solid #eee',
//     userSelect: 'auto',
//   },
// })(MuiExpansionPanelSummary);

// const ExpansionPanelDetails = withStyles((theme) => ({}))(MuiExpansionPanelDetails);

const Lead = ({lead, setShowDetail, setSelectedLead, fromSearch, setShowContactBottomSheet}) => {
  
  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [openDynamicForm, setDynamicForm] = useState(false);
  const history = useHistory();
  const documents = ['Aadhar', 'PAN', 'RC', 'Bank account'];
  // const handleExpansionPanelChange = (event, expanded) => {
  //   if (expanded === true) {
  //     logEvent(
  //       'ClickCandidateDetails',
  //       trackingConstants.EVENT_GROUP_VIEW_LEAD,
  //       fromSearch
  //         ? trackingConstants.EVENT_SCREEN_SEARCH
  //         : trackingConstants.EVENT_SCREEN_CANDIDATE
  //     );
  //   }
  // };

  // const handleOpenAndAddDocuments = async () => {
  //   if (lead && lead.id && lead.mitraId) {
  //     logEvent(
  //       'ClickDocumentsPage',
  //       trackingConstants.EVENT_GROUP_VIEW_LEAD,
  //       fromSearch
  //         ? trackingConstants.EVENT_SCREEN_SEARCH
  //         : trackingConstants.EVENT_SCREEN_CANDIDATE
  //     );
  //     window.open(process.env.REACT_APP_JF1_BASE_URL + `/documents?l=${lead.id}&m=${lead.mitraId}`);
  //   } else {
  //     console.log('Lead and Mitra id not found');
  //   }
  // };
  // const openForm = (lead) => {
    // localStorage.setItem('lead', JSON.stringify(lead))
    // setDynamicForm(true);
  // };

  // const checkClientAdditionalForm = (clientName) => {
  //   return clientName in AdditionalFormsURLEnum;
  // };

  const checkAtRisk = (date, companyName) => {
    const DateObj = new Date();
    const differnceInDates = (DateObj.getTime() - Date.parse(date))/(1000 * 3600 * 24);
    if(differnceInDates >= 7 && differnceInDates <= 14 && companyName === "Shadowfax"){
      return true
    }
    return false
  }

  return (
    <div className="detail-body-container">
      <div>
        {lead.dateShow ? <div className="grouping-date"> {lead.createdAtForGrouping}</div> : null}
      </div>
      <div className="detail-element-body">
        <div className="card-container">
          <div style={{width: '85%'}}>
            <div className="contact-details-container">
              <div className="contact-details">
                <div className="lead-name">
                  {lead.name}
                </div>
                <div className="phone-container" onClick={(e) => {
                  if(window.innerWidth > 700){
                    setShowDetail(true);
                  }
                  else {
                    setShowContactBottomSheet(true);
                  }
                  // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
                  setSelectedLead(lead);
                  e.stopPropagation();
                  }}>
                  <i className="fas fa-phone phone-icon"></i>
                  <div className="phoneNumber">{lead.phoneNumber}</div>
                </div>
              </div>
            </div>
            <div className="job-container">
              { 
              lead ? lead.jobs ?
                lead.jobs.map((job, index) => (
                  <div key={index} className="single-job-container">
                    <div className="client-logo">
                      <img className="imgResponse" src={"/image/" + job.companyName + ".png"} />
                    </div>
                    <div className="onboarding-schedule">
                    Status
                                <h6>{job.companyName && job.status ? (
                                  <span style={{color: job.status === 'Created' ? '#ff6b00' : job.status === 'Created' ? '#66ff66' : '#000'}}>
                                    {job.companyName.toLowerCase().includes('shadowfax') ? (
                                      <span>
                                        {job.status === 'Activated'
                                          ? 'Onboarded'
                                          : job.status === 'Uploaded'
                                            ? 'Pending for Referral'
                                            : job.status === 'Created'
                                              ? 'Unique'
                                              : job.status === 'Not Created'
                                                ? 'Not Unique'
                                                : null}
                                      </span>
                                    ) : (
                                      <span>{job.status === 'Uploaded' ? 'Unique' : null}</span>
                                    )}
                                    {job.status === 'Joined'
                                      ? 'Onboarded'
                                      : job.status === 'Expired'
                                        ? 'job Expired'
                                        : job.status === 'Order Started'
                                          ? 'First Trip Completed'
                                          : job.status === 'Not Uploaded'
                                            ? 'Not Unique'
                                            : job.status === 'Activated' ||
                                              job.status === 'Uploaded' ||
                                              job.status === 'Created' ||
                                              job.status === 'Not Created'
                                              ? null
                                              : job.status}
                                  </span>
                                ) : null}</h6>
                    </div>
                    <div className="complete-profile-button"></div>
                  </div>
                ))
                : null : null
              }
            </div>
          </div>
          <div className="user-image-view-detail">
            <div className="user-image-container">
              <div className="user-image">
                {lead.name.charAt(0).toUpperCase()}
                {
                  checkAtRisk(lead.jobs[0].createdAt, lead.companyName) ? <div className="counter counter-lg"><i className="fas fa-exclamation"></i></div> : null
                }
              </div>
            </div>
            <div className="view-details-container"
             onClick={(e) => {
            setShowDetail(true);
            // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
            setSelectedLead(lead);
            e.stopPropagation();
            }}>
            <i className="fas fa-chevron-right right-arrow"></i>
            <button className="view-details-button">View Details</button>
            </div>
          </div>
         
        </div>
        
      </div>
      <div>
        {' '}
        {lead.createdAtForSearch ? (
          <div className="lead-added">Added - {lead.createdAtForSearch}</div>
        ) : null}
      </div>
    </div>
  );
};
export default Lead;
